@import '__importable.scss';
$margin-gap: 0.48rem;

.container {
	max-width: 98vw;
	overflow: auto;
	padding: $margin-gap 0;
	position: relative;
	flex-wrap: wrap;
	gap: 0.8rem;
}

.imageOption {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 4.8rem;
	height: 4.8rem;
	border: 2px solid transparent;
	border-radius: 100%;
	transition: 200ms border-color ease-out;

	&[data-selected='true'],
	&:hover {
		border-color: $blue-1;
		transition: 200ms border-color ease-out;
	}
}

.swatch {
	@include swatch;
	width: 4rem;
	height: 4rem;
	object-fit: cover;
}

.selected {
	& img {
		box-shadow: 0 0 0 0.2rem #fff, 0 0 0 0.32rem $blue !important;
	}
}
