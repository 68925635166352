@import '__importable.scss';
.container {
	position: 'absolute';
	top: 0;
}

.pickerModal {
	border-radius: 0;
	overflow-y: hidden;
}

.pickerHeader {
	height: 4.4rem;
	border-width: 1px 0;
	border-color: $gray-2;
	border-style: solid;
	background-color: white;
	z-index: 2;
	pointer-events: all;
}

.pickerTitle {
	position: absolute;
	top: 50%;
	left: 50%;
	translate: -50% -50%;
}

.pickerBack {
	position: absolute;
	top: 50%;
	left: 1.6rem;
	translate: 0 -50%;
}

.pickerNext {
	position: absolute;
	top: 50%;
	right: 1.6rem;
	translate: 0 -50%;
}

.image-container {
	background: $gray-1;

	.image {
		padding: 2rem 5rem;
		mix-blend-mode: multiply;

		@media screen and (width > $media-sm-max) {
			padding: 0 7.3rem;
		}
	}
}

.heading {
	padding: 2.4rem 1.6rem 1.6rem;

	@media screen and (width > $media-sm-max) {
		padding: 2.4rem 3.2rem 1.6rem;
	}

	>h6 {
		margin-bottom: 0;
	}
}

.form-content {
	padding: 1.6rem;

	@media screen and (width > $media-sm-max) {
		padding: 0 3.2rem 3.2rem;
	}
}

.pdModal{
	z-index: $zindex-max-20 !important;
}

.pdModalOverlay{
	z-index: $zindex-max-20 !important;
}