@import '__importable.scss';
.container {
	position: relative;
	width: fit-content;
	white-space: nowrap;
}

.list {
	position: absolute;
	right: 0;
	gap: 0.8rem;
	background-color: $white;
	border: 1px solid $gray-2;
	box-shadow: 0 0.8rem 0.8rem rgba(0, 0, 0, 0.16);
	border-radius: 0.4rem;
	width: 100%;
	min-width: fit-content;
	margin-top: 0.4rem;
	z-index: $zindex-10;

	opacity: 0;
	visibility: hidden;
	transform: translateY(-5%);
	transition: $transition-2 ease all;

	@media screen and (min-width: $media-md) {
		right: unset;
	}
}

.scroll {
	display: flex;
	padding: 1rem;
	max-height: 30rem;
	overflow-y: auto;
	pointer-events: all !important;
	width: 100%;
	position: relative;
}

.fade {
	min-height: 9.6rem;
	height: 100%;
	background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 13.54%, rgba(255, 255, 255, 0) 86.46%, #fff 100%);
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10;
	pointer-events: none !important;
}

.option {
	width: 100%;
	color: $blue-2;
	border-radius: 0.4rem;
	border: 2px solid transparent;
	outline: 0;
	cursor: pointer;
	min-width: max-content;
	height: 2.4rem;
	display: flex;
	align-items: center;
	gap: 0.4rem;
	padding: 0 0.4rem;

	&.is-instruction {
		color: $gray-4;
		pointer-events: none;
	}

	&.is-selected {
		svg path {
			stroke: currentColor;
		}
	}

	&.is-selected:not(.is-instruction) {
		color: $gray-0;
		background-color: $blue-1;
		transition: all $transition-3 ease;

		&:active {
			color: $gray-0;
			background-color: $blue-shade;
		}
	}

	&:hover:not(.is-selected) {
		background: rgba(0, 0, 0, 0.04);
		color: $blue-1;

		svg path {
			stroke: transparent;
		}
	}

	&:focus-visible {
		border-color: $pink-2;
		box-shadow: 0 0 0 1px $pink-2;
	}

	&:active:not(.is-selected) {
		color: $blue-shade;
		background: rgba(0, 0, 0, 0.08);
	}

	// fade hack
	&:first-child,
	&:last-child {
		z-index: $zindex-1;
	}
}

.caption {
	@include non-selectable;
}

.show {
	opacity: 1;
	transform: translateY(0%);
	visibility: visible;
	transition: $transition-2 ease all;
}
