@import '__importable.scss';
$img-left: 'image content';
$img-right: 'content image';
$stacked: 'image' 'content';
$stacked--flipped: 'content' 'image';

.container {
	display: grid;
	grid-template-areas: $stacked;
	grid-template-columns: 1fr;
	align-content: space-between;
	justify-items: center;
	overflow: hidden;
	margin-inline: auto;
	width: 100%;
	@include cf-height('image-container');
	@include cf-border-radius;

	[data-video-js] {
		width: 100%;
		height: 100%;
		mix-blend-mode: multiply;
	}

	&.cover .image-container {

		img,
		video {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}

	&.contain .image-container {

		img,
		video {
			object-fit: contain;
			width: 100%;
			height: 100%;
		}
	}

	&.force-mobile-style {
		@include cf-height('image-container', false);
		justify-items: start;
		grid-template-columns: 1fr;
		grid-template-areas: $stacked;
		align-content: unset;

		&.flipped {
			grid-template-areas: $stacked--flipped;
		}

		.content-container {
			justify-content: space-between;
		}
	}

	@media screen and (min-width: $media-md) {

		justify-items: start;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr;
		align-content: unset;
		grid-template-areas: $img-left;

		&.flipped {
			grid-template-areas: $img-right;
		}

		&:not(.force-mobile-style) {
			@include cf-height;
		}
	}
}

.content-container {
	grid-area: content;
	height: inherit;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: clamp(1.6rem, 5vw, 8rem);
	gap: 1.2rem;

	>* {
		margin: 0 !important;
	}

	@media screen and (max-width: $media-md){
		a,button{
			width: 100% !important;
		}
	}
}

.image-container {
	grid-area: image;
	height: inherit;
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;
	width: 100%;

	img {
		height: inherit;
	}
}

.button-center {
	align-items: center;
	a, button {
		align-self: center !important;
	}
}

.button-left {
	a, button {
		align-self: flex-start !important;
	}
}

.button-right {
	a, button {
		align-self: flex-end !important;
	}
}