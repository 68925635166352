@import '__importable.scss';
.container {
	min-width: 12.5rem;
	width: 12.5rem;
	height: 13.6rem;
	padding: 0.8rem 0 0.8rem 0;
	gap: 0.8rem;
	background-color: $gray-1;
	border: 1px solid $gray-2;
	border-radius: $radius-4;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	&:hover{
		background-color: rgba(54, 25, 25, .00004);
	}
	cursor: pointer;
	scroll-snap-align: center;
}

.swatchContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.8rem;
}

.wrapper {
	@include swatch-wrapper;
	&:hover {
		border-color: transparent;
	}
}

.swatch {
	@include swatch;
	box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.16);
	position: relative;
	cursor: auto;
}


.rainbow {
	width: 3.2rem;
	height: 3.2rem;
	border-radius: 50%;
	background: conic-gradient(#14c926, #00a3ff, #1400ff, #ff0000, #e6ea08, #14c926);
}

.richMedia {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	background-color: $gray-1;
	flex: 1;

	&Img {
		object-fit: contain;
		mix-blend-mode: multiply;
		width: 100%;
	}
}