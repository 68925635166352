@import '__importable.scss';
.card-placeholder {
	width: 100%;
	background-color: $gray-0;
	border: 1px solid $gray-2;
	border-radius: 1rem;

	.image-placeholder {
		width: 100%;
		height: auto;
		background-color: $gray-2;
	}

	.content-placeholder {
		width: 100%;
		height: auto;
		padding: 0.8rem;
		min-height: 5rem;
	}
}
