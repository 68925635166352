@import '__importable.scss';
.container {
	position: 'absolute';
	width: 100%;
	top: 0;
}

.table-container {
	padding-inline: 1.6rem;

	@media screen and (min-width: $media-md-max) {
		padding-inline: 3.2rem;
	}
}

.table {
	border: 1px solid $gray-2;
	border-radius: 10px;
	margin: 1rem 0;
	width: auto;
	background-color: $white;
	user-select: none;

	thead {
		border-bottom: 1px solid $gray-2;
	}

	th {
		padding: 1rem 2rem;
		width: 30%;

		p {
			font-weight: 500;
		}
	}

	tr {
		border-top: 1px solid $gray-2;
		border-bottom: 1px solid $gray-2;

		&:first-child {
			border-top: none;
		}

		&:last-child {
			border-bottom: none;
		}

		&[data-row-type='warning'] {
			border-top: none;
			background-color: #{$yellow-1 + '31'};
			color: $yellow-4;

			td {
				padding: 0.4rem 0.8rem;
			}

			svg {
				margin-right: 0.4rem;
			}
		}
	}

	td {
		padding: 2rem;

		&:first-child {
			border-right: 1px solid $gray-2;
		}

		&:not(:first-child) {
			text-align: center;
		}

		p {
			font-weight: 500;
		}
	}

}

.subtitle {
	color: $gray-4;
}

.cell {
	color: $gray-4;
}

.icon {
	flex-shrink: 0;
}

.warning {
	padding: 0.8rem!important;
}

.heading {
	padding: 4rem 1.6rem 1.6rem;

	>h6 {
		margin-bottom: 0;
	}

	@media screen and (min-width: $media-md-max) {
		padding: 4rem 3.2rem 1.6rem;
	}
}
