@import '__importable.scss';
.skeleton-button-container {
	padding: 1.6rem 1.6rem 0;

	@media screen and (min-width: $media-md) {
		padding: 3.2rem 3.2rem 0;
	}
}

.skeleton-button {
	@include skeleton-loader();
	width: 20rem;
	height: 6rem;
}

.skeleton-product-list {
	display: grid;
	max-width: $max-width;
	margin: auto;
	gap: 0.8rem;
	padding: 1.6rem;
	grid-template-columns: 1fr 1fr;
	width: 100%;

	@media screen and (min-width: $media-md) {
		padding: 3.2rem;
		gap: 1.6rem;
	}
	@media screen and (min-width: $media-xl) {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
}

.skeleton-top-frame {
	@include skeleton-loader();
	width: 14rem;
	height: 25rem;

	@media screen and (min-width: $media-md) {
		width: 25rem;
	}

	@media screen and (min-width: $media-lg) {
		width: 23rem;
	}

	@media screen and (min-width: $media-xxxl) {
		width: 35rem;
		height: 35rem;
	}
}
