@import '__importable.scss';
.container {
	height: 100%;
	background-color: $white;
	border-radius: $radius-7 $radius-7 0 0;
	padding: 4rem 1.6rem;
	margin-top: -3.2rem;
	max-width: 57.6rem;
	position: relative;
	z-index: $zindex-1;
	overflow-y: auto;

	h1 {
		font-size: 3rem;
		margin-top: 3.2rem;
	}

	@media screen and (min-width: $media-sm) {
		border-radius: $radius-7;
		margin-top: 0;
		padding: clamp(4rem, 6vw, 8rem);

		h1 {
			margin-top: 0;
		}
	}

	@media screen and (min-width: $media-xxl) {
		padding: clamp(4rem, 7vw, 8rem);
	}
}

.backLink {
	position: absolute;
	top: 2.4rem;
	left: 0.8rem;
	display: flex;
	align-items: center;
	color: $blue;
	z-index: $zindex-1;

	@media screen and (min-width: $media-sm) {
		top: 2rem;
		left: 2rem;
	}
}

.nameLine {
	display: flex;
	gap: 0.8rem;

	> div {
		width: 100%;

		@media screen and (min-width: $media-sm) {
			width: 50%;
		}
	}
}

.buttons {
	display: flex;
	gap: 1.6rem;
	margin-top: 3.2rem;

	@media screen and (min-width: $media-sm) {
		justify-content: flex-start;
	}
}

.signInMessage,
.termsAndConditionsMessage {
	color: $gray-4;
	a {
		color: $blue;
	}
}

.signInMessage {
	margin-bottom: 2rem;
}

.termsAndConditionsMessage {
	margin-top: 1.6rem;
}
