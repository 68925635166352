@import '__importable.scss';
.container {
	display: flex;
	flex-direction: column;
	padding: 3.2rem 0.8rem;
	gap: 2.4rem;
	border-bottom: 1px solid $gray-2;
	max-width: $max-width;

	@media screen and (min-width: $media-md) {
		padding: 3.2rem 1.6rem;
    }
    @media screen and (min-width: $media-xl) {
		padding: 4rem 3.2rem;
    }
    @media screen and (min-width: $media-xxl) {
		padding: 4rem 6.4rem;
    }
	@media screen and (min-width: $max-width) {
		padding: 4rem 12.8rem;
    }	
}

.grid {

	display: grid;
	grid-template-columns: 1fr;
	gap: 0.8rem;

	> * {
		width: 100% !important;
		min-width: unset !important;
		height: 24rem !important;
	}


	@media screen and (min-width: $media-md-max) {
		grid-template-columns: 1fr 1fr;
    }
}
