@import '__importable.scss';
.container {
	display: flex;
	flex-direction: column-reverse;

	@media screen and (min-width: $media-md) {
		height: calc(100vh - $headerHeight);
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		> * {
			flex-basis: 50%;
		}

		> img {
			width: 50%;
		}
	}

	@media screen and (width >= $max-width) {
		height: calc(100vh - $headerHeight - $footerHeight);
	}

	img {
		object-fit: cover;
		height: 100%;
		position: relative;
		z-index: $zindex-behind;
	}
}

.reverseDirection {
	flex-direction: column-reverse;

	@media screen and (min-width: $media-md) {
		flex-direction: row-reverse;
	}
}

.formWrapper {
	height: calc(100vh - $headerHeight);
	background-color: $lightblue-shade;
	flex-basis: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	@media screen and (min-width: $media-sm) {
		padding: 7rem 5.5rem 5.5rem 5.5rem;
	}
}

.headerWrapper {
	height: calc(100vh - $headerHeight);
	position: relative;
	display: flex;
	align-items: flex-start;
}

.header {
	display: flex;
	flex-direction: column;
	top: 11.5rem;
	left: 4rem;
	position: absolute;
	z-index: $zindex-1;
	padding: 1rem;
	gap: 3rem;

	ul {
		padding: 0;
		margin: 0;
		list-style-type: none;
		display: flex;
		flex-direction: column;
		gap: 1rem;

		span {
			margin: 0;
			line-height: 1.6rem;
			padding-left: 2rem;

			&::before {
				content: '✓';
				position: absolute;
				left: 1rem;
				font-weight: bold;
			}
		}
	}

	@media screen and (max-width: $media-sm) {
		top: 5.5rem;
		gap: 1.5rem;

		ul {
			gap: 0.2rem;

			span {
				line-height: 1.4rem;
			}
		}
	}
}
