@import '__importable.scss';
.container {
    padding: 0.8rem 0.8rem;
    @media screen and (min-width: $media-md) {
        padding: 0.8rem 6.4rem 0.8rem 6.4rem;
    }
	&:last-child:not(:first-child) {
		margin-bottom: 3.2rem;
	}
}

.header{
    h4{
        font-size: 3.2rem;
    }
}

.without-icons{
    svg{
        display: none;
    }
}

.cards {
    padding-top: 1.6rem;
    gap: 3.2rem;    
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @media screen and (min-width: $media-md) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (min-width: $media-lg) {
        grid-template-columns: repeat(4, 1fr);
    }
}