@import '__importable.scss';
.container {
	display: flex;
	flex-direction: column;
	gap: 2.4rem;

	padding-top: 3.2rem;

	@media screen and (min-width: $media-md) {
		padding-top: 4rem;
	}
}
.homepagePadding {
	padding-inline: 0.8rem;

	@media screen and (min-width: $media-md) {

		padding-inline: 1.6rem;
	}

	@media screen and (min-width: $media-xl) {
		padding-inline: 3.2rem;
	}
	
	@media screen and (min-width: $media-xxl) {
		padding-inline: 6.4rem;
	}

	h3 {
		@media screen and (max-width: $media-sm) {
			font-size: 2.8rem;
		}
	}
}
.content {
	@extend .homepagePadding;
	scroll-snap-type: x mandatory;
	display: flex;
	width: 100%;
	overflow-x: scroll;
	overflow-y: visible;
	gap: 0.4rem;

	@include hide-scroll-bar;

	@media screen and (max-width: $media-xl) {
		> * {
			min-width: 336px;
			scroll-snap-align: center;
		}
	}

	[class*="SwatchController"] {
		flex-wrap: wrap;
	}

	@media screen and (min-width: $media-md) {
		gap: 0.8rem;
		padding-bottom: 1px; // create space for hover effect
	}
}
.cards {
	@extend .homepagePadding;
	display: flex;
	flex-direction: column;
	padding-top: 2.4rem;
	padding-bottom: 3.2rem;
	gap: 0.8rem;

	> * {
		width: 100% !important;
		border-radius: $radius-4 !important;
	}

	@media screen and (min-width: $media-md-max) {
		flex-direction: row;
		padding-bottom: 4rem;
	}
}

.buttonContainer {
	@extend .homepagePadding;	
	padding-top: 1.6rem;

	@media screen and (min-width: $media-xl) {
		display: none;
	}
}

.button {
	@include navigationButton;
	display: flex !important;
	position: initial;
	height: 3.2rem;
	width: 3.2rem;
}

.loadingWrapper {
	height: 81.2rem;
	width: 100%;
	max-width: $max-width;
	margin-inline: auto;
	overflow: hidden;

	@media screen and (min-width: $media-md) {
		height: 61.5rem;
	}

	@include loading-wrapper-shimmer;
}