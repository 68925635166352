@import '__importable.scss';
.pagination {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0.3rem 0.8rem 0.1rem;
	gap: 0.8rem;
	width: fit-content;
	max-width: 90%;
	height: 4.8rem;
	background: $gray-0;
	border: 1px solid $gray-2;
	border-radius: 2.4rem;
	margin: 0 auto;
	position: sticky;
	bottom: 2.4rem;
	box-shadow: 0 4px 4px rgba(0, 0, 0, .16);
}

.list {
	display: flex;
	align-items: center;
	height: 4rem;
	gap: 0.8rem;
	color: $blue-1;

	>li {
		>button {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0.9rem 0.8rem 1rem;
			width: 3.2rem;
			height: 3.2rem;
			border-radius: $radius-2;
			position: relative;
			cursor: pointer;
			color: $blue-1;
			transition: $transition-3;

			&[disabled] {
				color: $gray-2;
				pointer-events: none;
			}

			&:not([disabled]) {
				&:focus-visible {
					border-color: $pink-2;
					box-shadow: 0 0 0 1px $pink-2;
				}
			}

			&:hover:not([disabled]) {
				color: $blue-1;
				background-color: $gray-2;
				transition: $transition-3;
			}

			&:active:not([disabled]) {
				color: $gray-0;
				background-color: $blue-1;
				transition: $transition-3;
			}
		}
	}

	.current::after {
		content: "";
		opacity: 1;
		position: absolute;
		left: 0;
		bottom: -0.6rem;
		width: 100%;
		height: 0.4rem;
		background-color: $blue-1;
	}

	.icon {
		border-radius: $radius-round;

		&[disabled] {
			color: $gray-2;
			pointer-events: none;
		}
	}
}

.loading {
	display: block;
	width: 2.4rem;
	height: 2.4rem;
	border: 4px solid hsla(0.67, 0.93, 0.30, 0.15);
	border-radius: $radius-round;
	border-top-color: $blue-1;
	animation: loginButtonSpinner 1s ease-in-out infinite;
	-webkit-animation: loginButtonSpinner 1s ease-in-out infinite;


	@keyframes loginButtonSpinner {
		to {
			-webkit-transform: rotate(360deg);
		}
	}

	@-webkit-keyframes loginButtonSpinner {
		to {
			-webkit-transform: rotate(360deg);
		}
	}
}