@import '__importable.scss';
.checkboxContainer {
	border: none !important;
	padding: 0 0.8rem !important;
	@media (hover: hover) {
		&:active {
			background-color: $white !important;
			border: none !important;
		}
	
		&:hover:not(:active):not(:focus-visible) {
			border: none !important;
			box-shadow: none !important;
		}
	}
}

