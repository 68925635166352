@import '__importable.scss';
.container {
	display: flex;
	gap: 1.6rem;
	position: sticky;
	top: $headerHeight;
	z-index: $zindex-3;
	background-color: $white;
	height: 4.8rem;
	padding: 0.2rem 1.6rem;
	overflow-x: scroll;

	-ms-overflow-style: none;
	scrollbar-width: none;
	&::-webkit-scrollbar {
		display: none;
	}
}

.item {
	padding: 8px;
	position: relative;
	border-radius: 0 !important;
	color: $gray-3;
	display: flex;
	align-items: center;
	margin-bottom: 0.2rem;
	white-space: nowrap;

	cursor: pointer;

	&:hover {
		color: $blue-tint;
		background-color: $gray-2;
	}
}

.selected {
	@extend .item;
	background-color: $white;
	color: $black;
}

.underline {
	position: absolute;
	bottom: -4px;
	height: 4px;
	left: 0;
	width: 100%;
	background-color: $blue;
}
