@import '__importable.scss';
.cart-actions {
	grid-area: cart;
	padding: 1.6rem;
	border-top: 1px solid $gray-2;
	border-left: 1px solid $gray-2;
	border-right: 1px solid $gray-2;
	border-radius: $radius-4 $radius-4 0 0;
	background: $gray-0;

	@media screen and (min-width: $media-lg) {
		margin-top: auto;
	}

	@media (width < $media-sm) {
		padding: 1.2rem;
	}
}

.button-container {
	display: flex;
	gap: 1rem;

	@media screen and (max-width: $cart-action-break) {
		.next,
		.prev {
			line-height: 1;
			padding-inline: 1rem;
			font-size: 1.4rem;
		}
	}
}

.hidden {
	display: none;
}

// Smaller screens with less height on mobile
@media screen and (max-width: $media-xsm) and (min-height: $media-sm) {
	.button-container {
		gap: 0.8rem;
	}
}
