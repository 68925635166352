@import '__importable.scss';
.container {
	min-width: 100vw;
	padding: 4.8rem 10% 6rem;

	.article {
		h1 {
			line-height: 3.8rem;
		}

		.date {
			color: $gray-4;
			padding: 1.2rem 0 2.8rem;
		}

		// These styles are for the parsed html article content
		.content {
			text-align: left;
			font-size: 1.8rem;
			font-weight: 300;

			.h1,
			.h2,
			.h3,
			h1,
			h2,
			h3 {
				font-size: 2.5rem;
				font-weight: 500;
				letter-spacing: 0.004em;
				line-height: 4.8rem;
				margin-bottom: 0.8rem;
			}

			b,
			strong {
				font-weight: bolder;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			a {
				color: $blue-1;
				
				@media (hover: hover) {
					transition: $transition-3;
					
					&:hover {
						text-decoration: underline;
						transition: $transition-3;
					}
				}
			}

			p {
				font-weight: 300;
				font-size: 1.8rem;
				line-height: 2.8rem;
				max-width: 100%;
				padding-top: 1.2rem;
				text-overflow: ellipsis;
				overflow: hidden;
				margin-bottom: 1.6rem;
			}

			h2 {
				margin: 5rem 0 1.6rem;
				font-size: 3.6rem;
				letter-spacing: 0.0036em;
				line-height: 4.32rem;
			}

			h3 {
				font-size: 3.2rem;
				letter-spacing: 0.0032em;
				line-height: 3.84rem;
			}

			blockquote {
				min-width: 100% !important;
			}

			ol,
			ul {
				margin: 0;
				padding: 0;
			}

			ul {
				list-style-type: disc;
				margin-left: 4rem;
			}

			li {
				list-style-type: inherit;
				font-size: 1.6rem;
				font-weight: 400;
				line-height: 2.4rem;

				p {
					margin-bottom: 0;
				}
			}

			@media screen and (max-width: $media-xsm) {
				iframe {
					width: 100%;
					height: 100%;
				}
			}
		}

		@media screen and (min-width: $media-md) {
			max-width: 90rem;
		}
	}
}
