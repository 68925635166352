@import '__importable.scss';
.container {
	padding: 6.4rem 3.2rem;
	text-align: left;
	align-items: flex-start;
	justify-content: center;
	background-size: cover;

	h2 {
		margin-bottom: 1.6rem;
	}

	@media screen and (min-width: $media-md) {
		height: 36.3rem;
		padding-inline: 12.8rem;

		&[data-alignment="center"] {
			text-align: center;
			align-items: center;
		}
		
		&[data-alignment="right"] {
			text-align: right;
			align-items: flex-end;
		}

		p,
		h2 {
			width: 100%;
			max-width: 60%;
			margin-inline: auto;
		}
	}
}