@import '__importable.scss';
.container {
	@include navbarUserActionIcon;

	a {
		width: inherit;
		height: inherit;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&[data-microcart-open="true"] {

		@media screen and (hover: hover) {
			background-color: unset;
		}

		&:active {
			background-color: unset;
			border-color: transparent;
		}
	}
}

.microcart {
	position: absolute;
	top: 35px;
	right: -10px;
	border-radius: 0.8rem;
	width: 359px;
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
	padding: 1.6rem;
	background-color: $gray-0;
	border: 1px solid $gray-2;
	box-shadow: 0 0.8rem 0.8rem 0 #00000040;

	@media screen and (min-width: $media-md) {
		top: 35px;
		right: -13px;
	}

	@media screen and (hover: hover) {
		cursor: default;
	}
}

.header {
	max-height: 3.2rem;

	>[class*='Caption'] {
		margin-right: auto;
	}
}

.caret {
	position: absolute;
	top: -9px;
	right: 13px;
	height: 16px;
	width: 16px;
	background-color: $gray-0;
	border-top: 1px solid $gray-2;
	border-left: 1px solid $gray-2;
	transform: rotate(45deg);

	@media screen and (min-width: $media-md) {
		right: 16px;
	}
}