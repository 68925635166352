@import '__importable.scss';
.fit-diagram {
    height: 100%;
    img {
        max-width: 100%;
        width: 100%;
        height: auto;
        border-radius: 0.5rem;

        &:not(.mobile) {
            display: none;
        }
    }
}

@media screen and (min-width: $media-md) {
    .fit-diagram {
        img {
            &.mobile {
                display: none;
            }

            &:not(.mobile) {
                display: block;
            }
        }
    }
}

.content {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	margin-top: 1.6rem;

	@media screen and (min-width: $media-sm) {
		gap: 0.8rem;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
	}
}

.container {
	background-color: $gray-1;
	border: 1px solid $gray-2;
	padding: 1.6rem;
	border-radius: 0.8rem;
}

.title {
	font-size: 1.8rem;
	font-weight: 500;
	font-family: var(--platform);
}

.faceShape {
	gap: 0.8rem;
	padding-top: 0.8rem;

	img {
		width: 2.6rem;
		height: auto;
	}
}
