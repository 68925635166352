@import '__importable.scss';
.container {
	text-align: center;
	color: $gray-5;
	width: 100%;
	height: 100%;
	flex-direction: column;
	flex: 1;

	@include cf-max-width;

	.image-container {
		img {
			width: auto;
			height: 18.5rem;
			margin-bottom: 1.6rem;
		}
	}

	.custom-image-container {
		img {
			width: auto;
			height: auto;
		}
	}

	[data-video-js],
	video {
		width: 100%;
		max-width: inherit;
		height: 18.5rem;
		margin-bottom: 1.6rem;
		mix-blend-mode: multiply;
		object-fit: cover;

		video-js {
			background-color: $gray-0;
		}
	}

	.content {
		padding: 0.8rem;
		text-align: inherit;

		[class*="Heading"] {
			margin-bottom: 0.8rem;
		}
	}

	&--summary {
		flex: 1;
		flex-direction: row;
		justify-content: start;

		.image-container {
			img {
				max-width: 8rem;
				max-height: 8rem;
				border-radius: 0.8rem;
				border: 1px solid $gray-2;
			}
		}

		.content {
			text-align: left;
			width: 100%;
		}

		@media screen and (min-width: $media-sm) {
			flex-direction: column;
			justify-content: inherit;

			.image-container {
				width: 100%;
				display: flex;
				align-content: center;
				justify-content: center;
				border-radius: 0.8rem;
				border: 1px solid $gray-2;
				height: 50%;

				img {
					width: auto;
					border: none;
				}
			}

			.content {
				text-align: inherit;
				height: 50%;
			}
		}

		@media screen and (min-width: $media-xxl) {
			.image-container {
				img {
					max-width: 11.8rem;
					max-height: 11.8rem;
				}
			}
		}
	}
}

.full-width {
	width: auto;
	@media screen and (min-width: $media-sm) {
		width: 100%;
	}
}

.is-clickable {
	@include hoverableNoPadding($gray-3);
	@extend .full-width;
	border: none;
	border-radius: $radius-4;
}
