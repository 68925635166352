@import '__importable.scss';
.flag {
	display: flex;
	align-items: center;
	background-color: $blue-2;
	color: $gray-0;
	padding: 0.2rem 0.4rem;
	border-radius: $radius-1;
	width: fit-content;
}
