@import '__importable.scss';
.container {
	width: 100%;
}

.fieldsCopy {
	padding-bottom: 1.6rem;
	border-bottom: 1px solid $gray-2;
}

.fieldOption {
	width: 100%;
	height: 4.4rem;
	border: 0 !important;
	border-radius: 0 !important;

	&:focus-visible {
		box-shadow: none !important;
		outline: none !important;
		border: 0 !important;
	}

	@media (hover: hover) {
		&:hover {
			border: 0 !important;
			box-shadow: none !important;
			background: $gray-1;
		}
	}
}

.image {
	display: block;
	height: 240px;
	width: 100%;
	object-fit: contain;
	background-color: $lightblue-shade;
}

.content-container {
	flex-grow: 1;
	padding: 1.6rem;

	@media screen and (min-width: $media-md-max) {
		padding: 1.6rem 3.2rem;
	}
}
