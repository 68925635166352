@import '__importable.scss';
.container {
	width: 100%;
	margin: 0 auto;
	border-radius: 1.6rem;
	background-color: $white;
	scroll-margin-top: calc($headerHeight + 4.8rem + 3.2rem - 2px);
	max-width: $max-width;

	@media screen and (width >= $media-xl) {
		padding: 0 3.2rem;
	}

	@media screen and (width >= $media-xxl) {
		padding: 0 6.4rem;
	}

	@media screen and (width >= $max-width) {
		padding: 0 12.8rem;
	}
}

.heading {
	border-bottom: 1px solid $gray-2;
	padding: 1.6rem 0.8rem;

	@media screen and (min-width: $media-md-max) {
		padding: 1.6rem 1.6rem;
	}

	@media screen and (min-width: $media-xl) {
		padding: 1.6rem 0;
	}
}
