@import '__importable.scss';
.container {
	height: 100vh;
	width: 100%;

	& iframe {
		width: 100%;
		height: 100%;
	}
}
