@import '__importable.scss';
.container {
	height: 100%;
	background-color: $gray-0;
	transition: all;
	overflow-y: hidden;
}

.collection-banner {
	justify-content: start;
	height: 4.8rem !important;
	margin-top: 1rem;
	&--fullWidth {
		margin-top: 0;
		height: 10.4rem !important;
		@media screen and (min-width: $media-lg){
			height: 8.5rem !important;
		}
	}
	img{
		height: 100%;
	}
	
}

.collection-container {
	height: 100%;
    overflow-y: auto;
}