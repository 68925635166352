@import '__importable.scss';
.container {
	min-height: 36rem;
	max-height: 51.2rem;
	height: auto;
	background-position: center;
	border-radius: 0.8rem;
	background-size: cover;

	@media screen and (width >= $media-md-max) {
		height: 51.2rem;
	}
}

.content {
	align-items: center;
	padding: 3.2rem 1rem;
	text-align: center;

	.button,
	.buttons-container {
		margin-top: 1.6rem;
	}

	[class*='TypographyButton'] {
		max-width: 80%;
	}
}
