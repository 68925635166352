@import '__importable.scss';
.container {
	padding: 3.2rem;
}

.header {
	margin-bottom: 1.6rem;
	text-align: center;
}

.stacks {
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    gap: 4.8rem;

    > * {
        flex: 1;
    }

	@media screen and (min-width: $media-md) {
        display: flex;
        flex-flow: row;
        justify-content: space-evenly;
        align-items: flex-start;
        gap: 1.6rem;
    }

    &[data-mobile-2-columns='true'] {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;

		@media screen and (min-width: $media-md) {
			display: flex;
			flex-flow: row;
			justify-content: space-evenly;
			align-items: flex-start;
			gap: 4rem;
		}
    }
}

.checklist {
	margin: 3.2rem auto;
	max-width: 100%;
	flex-direction: column;
	align-content: flex-start;

	&__item {
		background-color: $white;
		padding: 0.8rem 1rem;
		text-align: left;
	}

	@media screen and (min-width: $media-md) {
		align-items: center;
		margin: 3.2rem auto;
		flex-direction: row;
		max-width: 80%;
	}
}

.links {

	@media screen and (max-width: $media-md-max) {
		display: none;
	}

	width: max-content;
	margin: 3.2rem auto;
}