@import '__importable.scss';
.container {
	width: 100%;
	margin: auto;
	max-width: $max-width;

	@media (max-width: $media-md-max) {
		padding: 0;
	}
}

.collectionHeader {
	height: 9.6rem;
	background-size: cover;
	background-position: center;
	border-radius: 0.8rem;
	width: 100%;
	position: relative;

	&.centerHeader {
		display: flex;
		justify-content: center;
	}

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		object-fit: cover;
		border-radius: 0.8rem;
	}

	a {
		position: absolute;
		top: 50%;
		translate: 0 -50%;
		right: 1.6rem;
		z-index: $zindex-2;
	}
}

.collectionNameContainer {
	border-radius: 0.8rem;
	z-index: $zindex-1;

	&--gradient {
		position: absolute;
		background: linear-gradient(183deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.16) 55.21%, rgba(0, 0, 0, 0.5) 100%);
	}
}

.subBannerContainer {
	width: 100%;
	min-height: 3.7rem;
	display: flex;
	justify-content: center;
	align-items: center;

	p {
		max-width: 100%;
		color: $white;
	}

	@media (max-width: $media-sm) {
		padding: 0.5rem;
		text-align: center;
	}
}

.collectionName {
	position: relative;

	.holidayCollection {
		padding: 0.5rem;
	}

	svg {
		height: 100%;
		width: 110%;
	}

	h5 {
		color: $white;
		text-shadow: $text-shadow-1;
	}
}

.collectionIcon {
	position: relative;
	img {
		position: relative;
		padding: 0 0.75rem;
		height: 5.6rem;
		width: auto;
		object-fit: contain;
	}
}

.productGrid {
	background-color: $white;
	width: 100%;
	border-radius: 0.8rem;

	@media screen and (min-width: $media-md) {
		border: 1px solid $gray-2;
	}
}

.button {
	padding: 0.8rem;
}

.emptyCardContainer {
	min-height: 40rem;
	width: 100%;
	padding: 3.2rem;
	border-radius: 0.8rem;
	background-color: $white;
	place-items: flex-start;

	@media (max-width: $media-md) {
		padding: 1.6rem;
	}
}

.noResults {
	width: 100%;
	display: flex;
	justify-content: center;
	text-align: center;
}
