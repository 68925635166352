@import '__importable.scss';
.container {
	padding: 1.6rem 0 0;

	@media screen and (max-width: $media-lg-max) {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
}

.contentCard {
	background-color: $gray-1;
	border: 1px solid $gray-2;
	padding: 1.6rem;
	border-radius: 0.8rem;

	img {
		mix-blend-mode: multiply;
	}
}

.title {
	font-size: 1.8rem;
	font-weight: 500;
	font-family: var(--platform);
}

.description {
	font-size: 1.2rem;
	color: $gray-4;
	font-weight: 500;
}