@import '__importable.scss';
$navbar-height: 6.4rem;
$sm-navbar-height: 4.4rem;
$banner-height: 4.8rem;
$mobile-navbar-height: 4.2rem;
$mobile-banner-height: 3.8rem;

.container {
	height: 100%;
	background-color: $gray-0;
	transition: all;
	overflow-y: hidden;
}

.navbar {
	background-color: $gray-0;
	display: flex;
	align-items: center;
	height: $sm-navbar-height;
	max-width: 100%;
	position: sticky;
	top: 0;

	@media screen and (width >= $media-lg) {
		height: $navbar-height;
		margin: 0;
	}
}

.navbar-button-container {
	height: 100%;
	padding-inline: 0.8rem;
	transition: all;
}

.navbar-scroll {
	display: flex;
	align-items: center;
	height: $navbar-height;
	background-color: transparent;
	list-style-type: none;
	white-space: nowrap;
	overflow-x: auto;
	-ms-overflow-style: none;
	scrollbar-width: none;
	padding-right: 1rem;

	@media screen and (width < $media-lg) {
		height: $sm-navbar-height;
	}

	&::-webkit-scrollbar {
		display: none;
	}

	.nav-item {
		height: 100%;
		padding: 0.4rem 0.8rem;
		margin-inline: 0.4rem;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		color: $gray-3;
		cursor: pointer;

		&::after {
			content: "";
			opacity: 0;
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 4px;
			background-color: $blue-2;
		}

		&.active::after {
			opacity: 1;
			transition: opacity 200ms;
		}

		&.active {
			color: black;
		}

		@media (hover: hover) {
			&:hover {
				color: $blue-1;
				background-color: $gray-2;

				&::after {
					background-color: $gray-2;
					transition: 0s;
				}

				&.active {
					&::after {
						opacity: 1;
						background-color: $blue-2;
						transition: 0s;
					}
				}
			}
		}
	}
}

.grouped-virtuoso {
	height: calc(100% - $sm-navbar-height)!important;

	@media screen and (width >= $media-lg) {
		height: calc(100% - $navbar-height)!important;
	}
}

.banner-image {
	width: 100%;
	height: $banner-height;
	position: absolute;
	object-fit: cover;
	z-index: 1;
	border-radius: 0.4rem;
}

.collection {
	background-color: $gray-0;
	scroll-margin-block: 4.4rem;
	position: relative;
	z-index: $zindex-1;
}

.group-header {
	color: $gray-0;
	grid-column-start: 1;
	grid-column-end: 4;
	width: 100%;
	height: $banner-height;
	position: sticky;
	top: 0;
	left: -1.6rem;
	border-radius: 0.4rem;
	overflow: hidden;
	z-index: $zindex-2;
}

.padded-top {
	@media screen and (min-width: $media-lg) {
		padding-top: 8.8rem;
	}
}

.floating-button-container {
	position: fixed;
	top: 5.6rem;
	z-index: $zindex-3;
	width: 100%;
}

.first-collection {
	padding-top: 4rem;

	@media screen and (min-width: $media-lg) {
		padding-top: 3rem;
	}
}

// Smaller screens with less height on mobile
@media screen and (max-width: $media-xsm) and (min-height: $media-sm) {
	.banner-image,
	.group-header {
		height: $mobile-banner-height;
	}
}

// iPad and Phone landscape stylings
@media only screen and (min-width: 66.7em) and (max-height: 37.5em) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2),
only screen and (min-width: 73em) and (max-width: 74em) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 3) {
	.banner-image,
	.group-header {
		height: 3.2rem;
	}
}

.copy{
	z-index: $zindex-3;
	padding: 1.6rem;
	text-shadow: $text-shadow-1;
	.title {
		margin-bottom: 0;
	}
	gap: 0.8rem;
}

.gradientOverlay {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.50);
	z-index: $zindex-2;
}
