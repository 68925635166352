@import '__importable.scss';
.about {
    >p {
        margin-bottom: 1.2rem;
    }
}

.buttons {
    display: flex;
    justify-content: space-between;
    gap: 1.6rem;
    margin-top: 1rem;

    [class*='pair-btn'] {
        flex: 1;
    }
}

.frame-sizing-modal {
    max-width: 64em;
}

.image {
    width: 100%;
    height: auto;
}