@import '__importable.scss';
.container {
	height: auto;
	text-align: center;
	padding: 6.4rem 3.2rem;

	h2 {
		margin-bottom: 1.6rem;
	}

	.reasons {
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: baseline;
		margin-bottom: 3.2rem;
	}

	.reason {
		flex: 0 0 calc(75% - 1.6rem);
		flex-direction: column;
		margin: 0 auto;

		video {
			width: 100%;
		}

		p {
			max-width: 24.8rem;
		}
	}

	&__benefits {
		margin: 2em 0;
		max-width: 100%;
		flex-direction: column;
		align-content: flex-start;
	}

	&__benefit {
		background-color: $white;
		padding: 0.5em 0.625em;
		text-align: left;
	}

	&__links {
		display: none;
	}

	@media screen and (min-width: $media-md) {
		.reasons {
			flex-direction: row;
			flex-wrap: nowrap;
			align-items: flex-start;
			margin-bottom: unset;
		}

		.reason {
			flex: 0 0 calc(25% - 1.6rem);

			video {
				width: 100%;
				height: 16.4rem;
			}
		}

		&__benefits {
			align-items: center;
			margin: 3.2rem auto;
			flex-direction: row;
			max-width: 80%;
		}

		&__benefit {
			background-color: $white;
			padding: 0.5em 0.625em;
		}

		&__links {
			display: flex;
			margin: auto;
			max-width: max-content;
		}
	}
}

.divider {
	color: $gray-3;
	font-size: 2rem;
	margin: auto 3.2rem;
}
