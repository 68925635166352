@import '__importable.scss';
.myFavorites {
	min-height: calc(100vh - $site-header-height - $tabbed-navigation-height);
	//padding: 1.6rem 0;
	//
	//@media screen and (min-width: $media-sm) {
	//	padding: 1.6rem 1.6rem 3.2rem;
	//}
	//
	//@media screen and (min-width: $media-lg) {
	//	padding-inline: 4.8rem;
	//}
	//
	//@media screen and (min-width: $media-xl) {
	//	padding-inline: 12.8rem;
	//}

	header {
		margin-inline: 1.6rem;

		@media screen and (min-width: $media-sm) {
			margin-inline: 0;
		}
	}

	> section {
		$cardComponentBuiltInMargin: 0.8rem;
		@include siteResponsiveCardGrid();
		gap: 0.8rem !important; // overrides mixin
		background: white;
		border-radius: 0.8rem;
		padding: 1.6rem 0 0.8rem;

		@media screen and (width >= $media-md-max) {
			padding: 1.6rem 0;
		}

		@media screen and (width >= $media-xl) {
			padding: 3.2rem 3.2rem;
		}

		@media screen and (width >= $media-xxl) {
			padding: 3.2rem 6.4rem;
		}

		@media screen and (width >= $max-width) {
			padding: 3.2rem 12.8rem;
		}

		> div:first-child {
			display: flex;
			align-items: center;
			grid-column: 1/-1;

			h2 {
				margin: 0 0 0 1.6rem;
			}
		}

		&.empty {
			display: block;
			margin-inline: 1.6rem;
			padding: 1.6rem;
			background: $gray-1;
			text-align: center;
			align-items: center;

			@media screen and (min-width: $media-sm) {
				margin-inline: 0;
			}

			svg {
				margin-bottom: 1.6rem;
				display: unset;
			}

			h5 {
				margin: 0;
				line-height: inherit;
			}

			button {
				display: unset;
				margin: 1.6rem auto 0;
			}
		}
	}
}
