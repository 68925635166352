@import '__importable.scss';
/*================= Info Module ===================*/
.container {
	padding-block: 4rem 0;
}

.heading {
	border-bottom: 1px solid $gray-3;
	padding-bottom: 1.6rem;
	margin-bottom: 2.4rem;
}

.content {
	display: flex;
	flex-flow: column;
}

.image-container {
	flex: 1;
}

.image {
	width: 100%;
	height: auto;
	object-fit: cover;
	border-radius: $radius-4;
}

.offerings {
	border: 1px solid $gray-2;
	padding: 1.6rem;
	border-radius: $radius-4;
	flex: 1.5;
	overflow-x: auto;
}

.tabs-list {
	border-bottom: 1px solid $gray-2;
	overflow-x: auto;
}

.nav {
	display: flex;
	justify-content: flex-start;
	align-items: flex-end;
	border-bottom: 1px solid $gray-2;
	height: 4.8rem;
}

.tab-pane {
	display: none;

	&[data-state='active'] {
		display: block;
	}
}

.content-container {
	border-radius: $radius-4;
	background-color: $gray-1;
}

@media screen and (min-width: $media-sm) {
	.content {
		display: flex;
		flex-flow: row nowrap;
	}

	.image-container {
		margin: 0;
		display: flex;
		height: unset;
		width: 100vw;
		max-width: 25.6rem;
		margin-left: unset;
	}

	.image {
		width: 25.6rem;
		height: auto;
	}
}

@media screen and (min-width: $media-md) {
	.container {
		flex-flow: row nowrap;
		max-width: unset;
		margin: 0 auto;
	}

	.image-container {
		margin: 0;
		display: flex;
		height: unset;
		max-width: 25.6rem;
		margin-left: unset;
		flex: none;
	}

	.image {
		width: 25.6rem;
		height: auto;
	}

	.offerings {
		flex: auto;
	}
}

@media screen and (min-width: $media-lg) {
	.image {
		max-width: 25.6rem;
		height: auto;
	}

	.image-container {
		max-width: 25.6rem;
		flex: auto;
	}

	.offerings {
		flex: auto;
	}
}

.tabsContent {
	padding: 1.6rem;
	margin-top: 1.6rem;

	@media screen and (min-width: $media-lg) {
		height: 100%;
	}
	@media screen and (min-width: $media-xl) {
		max-width: unset;
	}

	.content-list-item {
		position: relative;
		&:not(:last-child) {
			margin-bottom: 1.6rem;
		}

		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}
