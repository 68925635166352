@import '__importable.scss';
.container {
	height: 100%;
    display: flex;
    min-width: 12.8rem;
    padding: 1.6rem 0.8rem 0.8rem 0.8rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.8rem;

    @media screen and (min-width: $media-xl) {
        max-width: 36rem;
        padding: 1.6rem;
        gap: 1.6rem;
    }
}

.cardContent{
    border: 0;
}

.address{
    div{
        padding-inline: 0 !important;
    }
    p{
        margin-bottom: 0;
    }
}