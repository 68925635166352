@import '__importable.scss';
.container {
	background-color: $white;
	padding: 3.2rem 0 0;
	overflow: hidden;
	height: auto;
	width: 100%;
	max-width: 100vw;

	h4 {
		margin-bottom: 2.4rem;
	}

	@media screen and (width >= $media-xl) {
		padding: 0 3.2rem;
	}

	@media screen and (width >= $media-xxl) {
		padding: 0 6.4rem;
	}

	@media screen and (width >= $max-width) {
		padding: 0 12.8rem;
	}
}

.swiper {
	height: inherit;

	@media screen and (min-width: $media-xl) {
		overflow: visible;
	}
}

.button {
	height: 4rem;
	padding: 0 2.4rem;
	border-radius: 0.4rem;
	background-color: $white;
	border: 1.5px solid $gray-2;
	cursor: pointer;

	&:hover {
		background-color: $gray-2;
		transition: background-color 300ms ease;
	}

	@media screen and (min-width: $media-md) {
		padding: 0 3.2rem;
	}

	@media screen and (min-width: $media-xl) {
		padding: 0 4rem;
	}
}

.inactive {
	cursor: unset;

	&:hover {
		background-color: $white;
	}
}

.loading-wrapper {
	height: 81.2rem;
	width: 100%;
	max-width: $max-width;
	margin-inline: auto;
	overflow: hidden;

	@media screen and (min-width: $media-md) {
		height: 61.5rem;
	}

	@include loading-wrapper-shimmer;
}
