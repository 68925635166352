@import '__importable.scss';
.logoContainer {
	border-bottom: 1px solid $gray-3;
	padding-bottom: 1rem;
	margin-bottom: 1.6rem;

	& .logo {
		max-height: 4.6rem;
		object-fit: contain;
		object-position: left;
	}
}

.licensing {
	width: 100%;
	flex: 1;
	border: 1px solid $gray-2;
	padding: 1.6rem;
	border-radius: 1.6rem;
	font-size: 1.4rem;

	img {
		max-height: 25.6rem;
		object-fit: cover;
		width: auto;
		border-radius: 0.4rem;
	}

	:global {
		.licensing__caption {
			a {
				color: $blue-2;
				&:hover {
					opacity: 0.8;
				}
			}
		}

		.licensing__branding {
			font-weight: 300;
		}

		.licensing__description {
			em {
				font-weight: 300;
			}
			p {
				margin-bottom: 0.8rem;
			}
		}
	}
}

.imageContainer {
	@media screen and (max-width: $media-sm-max) {
		display: flex;
		flex-direction: column;
	}
}

.licensingContent {
	width: 100%;
	flex: 1;
	border: 1px solid $gray-2;
	padding: 1.6rem;
	border-radius: 1.6rem;

	img {
		border-radius: 0.4rem;
	}
}

