@import '__importable.scss';
.container-ctfl,
.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	height: auto;
	padding: 6.4rem 3.2rem;

	p {
		max-width: 35.2rem;
		margin: 0 auto;
	}

	>div {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-grow: 1;
		width: 100%;
		margin-top: 2.4rem;
		text-align: center;

		img,
		video {
			margin: auto;
			object-fit: contain;
		}

		>div:nth-child(1),
		>div:nth-child(3),
		>div:nth-child(5) {
			flex: 1 1 calc(28%);
		}

		>div[data-type='plus-sign'],
		>div[data-type='equal-sign'] {
			flex: 1 1 calc(8%);
			max-width: 11.2rem;
			position: relative;

			img,
			video {
				position: relative;
				top: 6.4rem;
				left: 50%;
				transform: translate(-50%, -50%);
				height: 100%;
				width: 100%;
				object-fit: contain;
			}
		}
	}
	
	video {
		width: 34.3rem;
		height: 17.2rem;
	}
}

@media screen and (width < $media-md-max) {
	.container-ctfl,
	.container {
		>div {
			flex-direction: column;
			align-items: center;
			margin-block: 0 1.6rem;

			>div[data-type='plus-sign'],
			>div[data-type='equal-sign'] {
				display: none;
			}
		}
	}
}

.container-ctfl {
	>div {
		>div:nth-child(1),
		>div:nth-child(2),
		>div:nth-child(3),
		>div:nth-child(5) {
			flex: 1 1 calc(33%);
		}
	}
}