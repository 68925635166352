@import '__importable.scss';
.item {
	$card-padding: 1.6rem;

	background-color: $white;
	border: 1px solid $gray-2;
	border-radius: $radius-4;
	padding: $card-padding;
	width: 100%;
	transition: $transition-3;
	outline: 0;

	&:not([disabled]) {
		&:focus-visible {
			border-color: $pink-2;
			box-shadow: 0 0 0 1px $pink-2;
		}
	}

	@media (hover:hover) {
		&:not([disabled]):not(.inactive) {
			&:hover {
				border-color: $blue-1;
				box-shadow: 1px -1px 0px 1px $blue-1 inset;
				padding-right: calc($card-padding - 1px);
				padding-top: calc($card-padding - 1px);
				padding-bottom: calc($card-padding + 1px);
				padding-left: calc($card-padding + 1px);
				cursor: pointer;

				>svg path {
					color: $blue-1;
				}
			}
		}
	}
}

.status {
	color: $blue-2;

	&[data-order-status='Cancelled'] {
		color: $orange-3;
	}

	&[data-order-status='Delivered'] {
		color: $green-2;
	}
}

.line-item-list {
	margin-left: 1rem;
}

.line-item {
	color: $gray-4;
	list-style: disc;
}

.removed {
	text-decoration: line-through;
	color: $gray-3;
}

.arrow {
	color: $blue-2;
	flex-shrink: 1;
	flex-grow: 0;
}

.tracking {
	color: $gray-4;
}