@import '__importable.scss';
$clear-width: 10.8rem;
$mobile-padding: 0.8rem;

.container {
	width: 100%;
	position: relative;
	z-index: $zindex-2;

	ul {
		display: flex;
		align-items: center;
		gap: 0.4rem;
		padding-inline: $mobile-padding;
		padding-right: $clear-width;
		overflow-x: scroll;
		scroll-padding-inline-end: $clear-width;
		mask-image:
			linear-gradient(to right,
				transparent 0,
				transparent 1px,
				#000 10px,
				#000 50%,
				transparent 50%,
				transparent 100%),
			linear-gradient(to left,
				transparent 0,
				transparent 40px,
				#000 140px,
				#000 50%,
				transparent 50%,
				transparent 100%);
		@include noScrollBar;

		>li {
			flex-shrink: 0;
		}
	}

	@media screen and (width > $media-lg) {		
		ul {
			padding-left: 0;
			mask-image:
				linear-gradient(to right,
					#000 10px,
					#000 50%,
					transparent 50%,
					transparent 100%),
				linear-gradient(to left,
					transparent 0,
					transparent 40px,
					#000 140px,
					#000 50%,
					transparent 50%,
					transparent 100%);
		}
	}
}

.clear {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	padding: 0;
	padding-left: $mobile-padding;
	position: absolute;
	top: 0;
	right: $mobile-padding;
	color: $blue-2;
	background: linear-gradient(90deg, transparent 0%, rgb(255, 255, 255) 10%);
	cursor: pointer;
	z-index: $zindex-2;

	@media screen and (width > $media-lg) {
		padding-bottom: inherit;

		&:hover {
			color: $blue-1;
		}
	}
}