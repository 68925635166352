@import '__importable.scss';
$bfNavHeight: 6.6rem;

.product-view {
	position: relative;
	z-index: $zindex-1;

	&[data-use-padding='true'] {
		padding-top: 0.6rem;
	}

	@media screen and (min-width: $media-md-lg) {
		height: calc(100vh - $bfNavHeight);

		&[data-use-padding='true'] {
			padding-top: 4.8rem;
		}

		&[data-use-height-tops-step='true']{
			height: auto;
		}
	}

	@media screen and (min-width: $media-lg) and (max-width: $media-xxl) {
		&[data-use-height-tops-step='true']{
			height: auto;
		}
	}

	@media screen and (width >=$max-width) {
		&[data-use-padding='true'] {
			padding-top: 1.6rem;
		}
	}

	h6{
		margin: 0;
		font-size: 2rem;
	}

	button{
		padding: 0;
		font-size: 1.2rem;
	}

	[class*="TopFrameEducation"] {
		display: inherit !important;
	}

	.no-preview{
		align-items: flex-start;
    	gap: 0.4rem;
		padding: 0 1.6rem 0.4rem;

		.preview-header {
			justify-content: start;
		}
	}
}

.shopping-bag-container {
	padding-right: unset;

	@media screen and (min-width: $media-md) {
		padding-right: 0.8rem;
	}
}

.shopping-bag-trigger {
	width: 4rem;
	height: 4rem;
	border-radius: 50%;
	border: 0.15rem solid $gray-2;
	display: flex;
	position: absolute;
	top: 0;
	right: 1.6rem;
	padding: 0.2rem;

	@media screen and (min-width: $media-md) {
		padding: 0.2rem;
		position: unset;
		top: unset;
		right: unset;
	}
}

.tops-counter {
	border-radius: $radius-round;
	width: 2.1rem;
	height: 2rem;
	color: $gray-0;
	background-color: $gray-3;

	&.is-active {
		background-color: $blue-1;
	}
}

.mobile-count {
	@extend .tops-counter;
	position: absolute;
	top: 0rem;
	right: 0.5rem;
	overflow: hidden;
	z-index: $zindex-2;

	&.is-active {
		background-color: $blue-1;
	}

	@media screen and (min-width: $media-md) {
		height: unset;
		top: 0.4rem;
		right: 0;
	}
}

.header {
	background-color: $gray-0;
	height: 5.6rem;
	border-radius: $radius-3 $radius-3 0 0;
	position: relative;
	top: -2.4rem;
	border-bottom: 1px solid $gray-2;

	>div {
		position: relative;
	}

	@media screen and (min-width: $media-md) {
		width: 100%;
		padding: 1.2rem;
		z-index: 10;
		position: sticky;
		top: 0;
		justify-content: space-between;
	}
}

.close-button {
	@include navigationButton;
	height: 2.6rem;
	width: 2.6rem;
	position: relative;
	justify-self: flex-start;
}

.drawerContent {
	max-height: 40vh;
	height: 100%;

	@media screen and (min-width: $media-md) {
		padding: 1.6rem;
		min-height: calc(100vh - 5.6rem - 9.4rem) ;
		max-height: fit-content;

		li {
			background-color: $gray-0;
		}
	}
}

.bf-drawer-cart-actions {
	width: 100%;
	z-index: $zindex-1;
	background: $gray-0;
	position: sticky;
	bottom: 0;

	@media screen and (min-width: $media-md) {
		border-radius: unset;
	}
}

.modalContent {
	@media screen and (min-width: $media-sm) {
		height: auto;
		max-height: 75vh;
		width: 100%;

		position: fixed;
		bottom: 0;

		top: unset;
		left: unset;
		translate: none;

		overflow-y: scroll;
		background-color: $white;
		padding: 3rem 1.6rem 0;

		border-radius: $radius-6 $radius-6 0 0;
		z-index: $zindex-2;

		-ms-overflow-style: none;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}
	}
}