@import '__importable.scss';
.myRewards {
	background: $gray-1;

	.responsiveContentContainer {
		padding: 0 3.2rem;

		@media screen and (width > $media-xxl) {
			padding: 0 6.4rem;
		}

		@media screen and (width > $max-width) {
			padding: 0 12.8rem;
		}
	}

	section {
		margin: 3.2rem 0 0 0;

		@media screen and (width >= $media-md) {
			max-height: 67.2rem;
		}

		&:first-child {
			margin: 1.6rem 0 0 0;
		}

		h2 {
			margin: 0 0 1.6rem 0;
		}

		p {
			display: none;
		}

		&.campaignsSection {
			p {
				display: block;
				margin: calc(-1.6rem + 2px) 0 1.6rem 0;
				color: $gray-4;

				@media screen and (min-width: $media-sm) {
					display: none;
				}
			}
		}
	}

	:global {
		// Yotpo Widget Custom CSS
		.yotpo-widget-override-css {
			&.yotpo-widget-campaign-widget {
				.yotpo-widget-campaign-widget-container {
					max-width: unset;
					padding: 0;
				}

				// Heading (Desktop)
				.yotpo-container-headline {
					display: none;
				}

				.flexified-wrapper {
					padding: 0;
					--flexified-padding: 0.8rem !important;
				}
			}

			&.yotpo-vip-tiers-widget {
				.yotpo-vip-tiers-list {
					padding: 0;
				}

				// Heading
				.yotpo-vip-tiers-headline {
					display: none;
				}

				// Progress Bar (Desktop)
				.yotpo-vip-tiers-progress-bar-wrapper {
					display: flex;
					flex-direction: column;
					background: transparent !important;
					width: auto;
					margin: 0;
					padding: 0;

					.yotpo-progress-bar-container {
						display: flex;
						flex-direction: column;
						order: 1;

						.yotpo-progress-bar-bottom {
							order: 1;

							.yotpo-progress-bar-bottom-left {
								font-size: 1.8rem !important;
								font-weight: normal !important;
								line-height: 2.7rem !important;
							}
						}

						.yotpo-progress-bar-top {
							order: 2;
						}

						.yotpo-progress-bar-outer {
							order: 3;
							height: 4px;
							background: rgb(0 0 0 / 12%);

							.yotpo-progress-bar-inner {
								background: #3431dc !important;
							}
						}
					}

					.yotpo-vip-tiers-progress-bar-summary {
						order: 2;

						.yotpo-vip-tiers-progress-bar-summary-next {
							span {
								color: #666163 !important;
								font-size: 1.4rem !important;
								font-weight: normal !important;
								line-height: 2.1rem !important;

								span {
									font-weight: inherit;
								}
							}
						}
					}
				}

				// Progress Bar (Mobile)
				.yotpo-is-mobile {
					.yotpo-vip-tiers-progress-bar-wrapper {
						display: flex;

						.yotpo-progress-bar-container {
							margin: 0 !important;
						}
					}
				}

				// Tier Cards (Desktop)
				.yotpo-vip-tiers-wrapper {
					max-width: unset;
					margin: 3.2rem 0 0 0;
				}

				// Tier Cards (Mobile)
				.yotpo-vip-tiers-mobile-wrapper {
					.yotpo-vip-tier-container {
						margin: 1.6rem 0 0 0;
						width: 100%;
					}
				}
			}
		}

		.yotpo-background {
			&.yotpo-left-align-background {
				img {
					height: 67.2rem !important;
					min-height: unset;
					object-position: 0 10%;
				}

				@media screen and (width < $media-md) {
					display: flex;
					flex-direction: column-reverse;

					img {
						width: 100%;
						max-height: 33.6rem;
						min-width: unset;
						max-width: unset;
					}
				}

				@media screen and (width >= $max-width) {
					height: auto !important;
					margin: 0 12.8rem;
				}
			}
		}
	}
}
