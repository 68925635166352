@import '__importable.scss';
.index-faq {
	padding: 1.6rem;
	margin-top: 0;
	background-position: center;

	&__container {
		display: flex;
		flex-direction: column;
		background-size: cover;
		padding: 1.6rem;
		border-radius: $radius-5;
		background: $gray-0;
		max-width: 120rem;
		margin: 0 auto;

		@media screen and (min-width: $media-md) {
			padding: 4rem;
		}
	}

	&__header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		&__button-desktop {
			display: none;

			@media screen and (min-width: $media-md) {
				display: block;
			}
		}
	}

	&__button-mobile {
		display: block;

		@media screen and (min-width: $media-md) {
			display: none;
		}
	}

	@media screen and (min-width: $media-md) {
		padding: 2.4rem;
	}

	@media screen and (min-width: $media-xl) {
		padding: 3.2rem;
	}

	@media screen and (min-width: $media-xxl) {
		padding: 6.4rem;
	}

	.itemContainer {
		margin-top: unset;
	}

	.row {
		padding: 2rem 0.8rem;

		@media screen and (min-width: $media-sm) {
			padding: 2rem 1.6rem;
		}
	}
}
