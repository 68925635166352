@import '__importable.scss';
.hero {
	width: 100%;
	height: 12.8em;
	margin-bottom: 5em;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.content {
	width: 50%;
	margin: 0 auto 2em;

	@media (max-width: $media-md) {
		width: 88%;
		padding-top: 2em;
	}


	h3 {
		text-align: center;
		margin-bottom: 4.8rem;

		@media (max-width: $media-md) {
			text-align: left;
		}
	}

	.instructions {
		margin: 4.8rem 0;
		background: $gray-1;
		padding: 1.6rem;
		border-radius: 0.8rem;

		.step {
			display: flex;
			flex-direction: column;
			align-items: center;

			.number {
				height: 1.5em;
				width: 1.5em;
				background-color: $blue-tint;
				border-radius: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				color: $white;
			}

			.border {
				border: 0.1rem dashed;
				height: 2.9rem;
				width: 0;
				margin: 2em 1em;
			}
		}

		.description {
			margin-bottom: 3em;
		}
	}
}
