@import '__importable.scss';
.container {
	background-color: $gray-0;
	padding: 1.6rem 1.6rem 0.8rem;

	.title {
		text-align: center;
		margin-bottom: 2.4rem;
	}

	@media screen and (min-width: $media-xl) {
		padding: 0 3.2rem;
		margin-bottom: 0.8rem;
	}

	@media screen and (min-width: $media-xxl) {
		padding: 0 6.4rem;
	}

	@media screen and (min-width: $max-width) {
		padding: 0 12.8rem;
	}

	&[data-page-type='tops-plp'] {
		margin: 0 auto;
		padding-inline: 0.8rem;
		padding-block: 1.6rem;

		@media screen and (min-width: $media-sm) {
			padding: 1.6rem;
		}

		@media screen and (min-width: $media-md) {
			padding: 3.2rem;
		}
	}
}

.blocks {
	flex-wrap: wrap;
	max-width: $max-width;
	margin: 0 auto;

	@media screen and (min-width: $media-md) {
		flex-wrap: nowrap;
	}

	[class*='Heading'] {
		text-shadow: 0 2px 3px $gray-0;
	}
}

.block {
	width: 100%;
}