@import '__importable.scss';
$media-xs: 43.3rem;

.section {
	margin: 1rem 2rem 2rem 2rem;
	padding: 0;

	&.full-bleed {
		margin: 0;
	}

	@media screen and (max-width: $media-md) {
		margin: 0 1rem 2rem 1rem;

		&.full-bleed {
			margin: 0;
		}
	}
}

.banner {
	flex-wrap: wrap;
	padding: 3.2rem;
	background-size: cover;
	background-position: center;
	border-radius: $radius-4;
	color: inherit;

	&:hover,
	&:focus {
		opacity: 1;
	}

	@media screen and (max-width: $media-md) {
		padding: 1.6rem;
	}

	.lozenge {
		width: fit-content;
	}

	&.full-bleed {
		justify-items: center;
		gap: 4.2rem;
		padding: 1.2rem;
		border-radius: 0;

		.name-container {

			// Status Badge
			>div:first-child {
				height: 2rem;

				p {
					font-size: 1.4rem;
				}
			}
		}

		@media screen and (max-width: $media-md) {
			gap: 1.6rem;
			padding: 1.6rem;
			display: flex;
			flex-direction: row;
		}

		@media screen and (max-width: $media-xs) {
			.name-container {

				// Status Badge
				>div:first-child {
					margin: 0.4rem auto 1.6rem;
				}
			}
		}
	}

	&.just-launched {
		@media screen and (max-width: $media-xs) {
			display: flex;
			flex-direction: column;
		}
	}
}

.name-container {
	max-width: 50%;
	padding-top: 0.8rem;

	@media screen and (max-width: $media-md) {
		align-self: flex-start;
		max-width: none;
	}

	&.just-launched-collection {
		@media screen and (max-width: $media-xs) {
			align-self: center !important;
		}
	}
}

.heading {
	position: relative;
	margin: 1.2rem 0 0 0;

	h6 {
		position: relative;
		z-index: 99;
		padding: 2.5rem 2rem;
		text-align: center;
	}

	svg {
		z-index: 1;
		height: 100%;
		width: 110%;
	}
}

.icon {
	position: relative;

	img {
		position: relative;
		z-index: 99;
		padding: 0 1.2rem;
		height: 100%;
		width: 100%;
		max-height: 5.6rem;
	}

	@media screen and (max-width: $media-md) {
		img {
			padding-left: 0.4rem;
		}
	}
}

.countdown {
	width: 100%;
	max-width: 35.2rem;
	padding: 1.6rem;
	background: $white;
	border: 1px solid $gray-2;
	border-radius: 0.8rem;

	@media screen and (max-width: $media-md) {
		padding: 1.2rem;
	}

	@media screen and (max-width: $media-sm) {
		margin: 0 2.4rem;
	}

	section {
		align-items: flex-start;
	}
}