@import '__importable.scss';
.image {
	padding: 10rem;
	background-color: $gray-1;
	border-bottom: 1px solid $gray-2;
	min-height: 70vh;

	@media screen and (max-width: $media-md) {
		padding: 6.4rem;
		min-height: initial;
	}

	& img {
		width: 51.2rem;
		height: 34rem;

		@media screen and (max-width: $media-md) {
			width: 25.6rem;
			height: 17rem;
		}
	}
	//min-height: 70vh
}
