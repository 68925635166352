@import '__importable.scss';
.section {
	background: transparent;
	padding-top: 2.4rem;
	padding-bottom: 0;

	@media (max-width: $media-md) {
		padding-top: 2.8rem;
	}

	@media screen and (min-width: $media-xl) {
		padding-bottom: 3.2rem;
	}
}

.removePadding {
	padding-bottom: 0rem;
}

.loading-wrapper {
	height: 19.6rem;
	width: 100%;
	overflow: hidden;

	@media screen and (min-width: $media-md) {
		height: 20.8rem;
	}

	@include loading-wrapper-shimmer;
}
