@import '__importable.scss';
.container {
  height: 120vh;
}

.iframe {
  height: 100%;
  width: 100%;
  border: none;
}
