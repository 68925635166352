@import '__importable.scss';
$cubic-transition: all cubic-bezier(0.19, 1, 0.22, 1) 200ms;
$title-header-height: 6.4rem;

@mixin hover-state($color) {
	&:not([disabled]):not(.hover-disabled) {
		&:focus-visible {
			border-color: $pink-2;
			box-shadow: 0 0 0 1px $pink-2;
		}

		@media (hover: hover) {
			@media screen and (min-width: $media-lg) {
				&:active {
					background-color: $gray-2;
					border-color: $color;
				}
			}

			&:hover:not(:active):not(:focus-visible) {
				box-shadow: 1px -1px 0px 1px $color inset;
				border-color: $color;
				cursor: pointer;

				.radio {
					&.selected .outer {
						background-color: $color;
						border-color: $color;
					}

					.outer {
						border-color: $blue-2;
					}
				}

				&[data-text-animate='true'],
				> [data-text-animate='true'] {
					transform: translate3d(2px, -2px, 0);
					transition: $cubic-transition;
				}
			}
		}
	}
}

.lens-list-item {
	display: flex;
	align-items: flex-start;
	width: 100%;
	background-color: $gray-0;
	padding: 1.6rem !important;
	border: 1px solid $gray-2;
	border-radius: $radius-4 !important;
	position: relative;
	transition: $cubic-transition;

	[data-text-animate='true'] {
		transition: $cubic-transition;
	}

	&.is-readers {
		display: flex;
		flex-flow: column nowrap;
	}

	> button[role='checkbox'] {
		margin-left: 0;
	}

	@include hover-state($gray-3);
}

.rx-list-item {
	@extend .lens-list-item;
	padding: 0rem !important;
	border-radius: $radius-2 !important;
	cursor: pointer;

	&.is-readers {
		border-radius: $radius-2 $radius-2 0 0 !important;
	}
}

.rxIcon {
	height: auto;
	max-height: 8rem;
	aspect-ratio: 4/3;
	width: 7.2rem;
	flex-shrink: 0;
	padding: 0.8rem;
	position: relative;
	top: 50%;
	translate: 0 -50%;
}

.rxIconLensExpansion {
	@extend .rxIcon;
	align-self: center;
	translate: none;
	aspect-ratio: unset;
}

.chevronContainer {
	flex-shrink: 0;
	display: flex;
	align-items: center;
}

.price {
	position: absolute;
	top: 3px;
	right: 1rem;
	color: $gray-4;
	font-weight: 500;
}

.readers {
	width: calc(100% + 2px);
	left: -1px;
	position: relative;
	border: 1px solid $gray-2;
	border-radius: 0 0 $radius-2 $radius-2;
	padding: 0.8rem 0.8rem 0.8rem 1.6rem;
}

.extra-z-index {
	z-index: 100;
}
