@import '__importable.scss';
.container {
	width: 100%;
	display: grid;
	margin-inline: auto;
	margin-block: 0;
	justify-content: center;
	justify-items: center;
	gap: 3.2rem;
	grid-template-rows: 1fr;
	grid-template-columns: 1fr;
	@include cf-margin-block;
	@include cf-max-width;

	&[data-component-name='Social Proof Badging'] {
		border-top: 1px solid $gray-2;
		border-bottom: 1px solid $gray-2;
		display: flex;
		padding-block: 4rem;
		max-width: 100%;
	}

	$sizes: 1, 2, 3, 4, 5, 6;

	@media screen and (width >= $media-md) {
		gap: 1.6rem;

		@each $size in $sizes {
			&[data-columns='#{$size}'] {
				grid-template-columns: repeat($size, 1fr);
			}
		}
	}

	&[data-columns='6'] {
		&[data-has-more-one-row='false']{
			flex-flow: row nowrap;
		}
	}

	@media screen and (width >= $media-xxsm) {
		padding: 0 0.8rem;
	}

	@media screen and (width >=$media-md-max) {
		padding: 0 1.6rem;
	}

	// Hack to fix container overflow on tablet view
	@media screen and (width <= $media-lg) {
		[class*='InteractiveCard'] {
			width: 100%;
			min-width: auto;
		}
	}

	@media screen and (width >=$media-xl) {
		padding: 0 3.2rem;
	}

	@media screen and (width >=$media-xxl) {
		padding: 0 6.4rem;
	}

	@media screen and (width >=$max-width) {
		padding: 0 12.8rem;
	}

	&.max-width-auto {
		width: 100%;
		max-width: unset;
		padding-inline: 0;
	}

	&:has(.background-image) {
		display: grid;
		grid-template-areas: 'stack';
		justify-content: unset;
		justify-items: center;
		align-items: center;

		>* {
			width: 100%;
			grid-area: stack;
			z-index: $zindex-2;
		}

		.stack-wrapper {
			display: flex;
			flex-flow: column;
		}
	}
}

.background-image {
	height: $height-256;
	background-size: cover;
	background-position: center center;
}

.ignore-padding {
	padding: 0 !important;
}
