@import '__importable.scss';
.price {
	color: $gray-4;
	font-weight: 500;
	&-red{
		color: $red-1;
	}
}

.compareAtPrice {
	color: $gray-3;
	font-size: 1rem;
	font-weight: 500;
	text-decoration: line-through;
	align-content: center;
}

.priceDescription {
	position: absolute;
	top: 3.5rem;
	right: 2rem;
	color: $gray-3;
	font-weight: 500;
	font-size: 1.2rem;

	&--withImage{
		top: 4.5rem;
	}
}

.tag{
	margin-bottom: 0.4rem;
}

.list-item {
	display: flex;
	align-items: center;
	width: 100%;
	padding: 1.2rem !important;
	border-radius: $radius-4 !important;
	border: 1px solid $gray-2;
	position: relative;
	z-index: 2;
	min-height: 6.2rem;

	> button[role='checkbox'] {
		margin-left: 0;
	}

	@include hoverable($gray-3);

	&--background {
		padding: 0 !important;
		background-color: $gray-2 !important;
	}

	.img-container{
		padding-left: 0.5rem;
		background-color: $gray-2;
		border-radius: $radius-4 0 0 $radius-4;
		.rich-media{
			background-color: transparent;
		}
	}

	.copy-container {
		background-color: $gray-0;
		padding: 1.2rem 1.2rem 1.2rem 0;
		border-radius: 0 $radius-4 $radius-4 0;
		min-height: 7.7rem;
		align-items: center;
		justify-content: space-between;
		padding-right: 2rem;

		&-radio{
			justify-content: space-between;
			padding-right: 1rem;
		}
	}

	&--selected {
		.copy-container {
			background-color: $gray-2 !important;
		}
		border: 1px solid $blue-shade;
	}

}
