@import '__importable.scss';
.container {
	position: fixed;
	top: 100vh;
	bottom: 0;
	display: flex;
	flex-direction: column;
	z-index: $zindex-11;
	border-radius: 1.6rem;
	width: 100%;
	background-color: $gray-0;
	overflow-y: scroll;
	@include noScrollBar;
	
	&--no-animation {
		position: sticky;
	    top: 15rem;
	    align-self: flex-start;
		left: unset;
		will-change: width, transform;
		height: auto;
		gap: 0.8rem;
		padding: 1.6rem 0;
		width: 32.2rem;
		z-index: $zindex-1;
		max-height: calc(100vh - $site-header-height - 9rem);
		overflow-y: auto;
		scrollbar-width: none;

		.header,
		.buttons {
			position: static;
			width: inherit;
		}
	}

	&--all-tops {
		top: 17rem;
		max-height: calc(100vh - $site-header-height - 9rem - 6.2rem);
	}

	&--bf-all-tops {
		top: 0;
		padding: 0;
	}

	&--hidden {
		display: none;
	}
}

.header {
	position: sticky;
	top: 0;
	background-color: $gray-0;
	padding: 1.2rem;
	z-index: $zindex-4;
	border-bottom: 1px solid $gray-2;
}

.buttons {
	position: fixed;
	width: 100%;
	bottom: 0;
	background-color: $gray-0;
	padding: 1.2rem;
	border-top: 1px solid $gray-2;
	z-index: $zindex-4;
}

.current-refinements {
	padding: 0 1.6rem 1.6rem 1.6rem;

	@media screen and (min-width: $media-lg) {
		padding: 0;
	}
}

.search-frame-selector {
	margin-top: 1.6rem;
}


.filter-overlay{
	position: fixed;
    inset: 0;
	&-open{
		background-color: rgba(0,0,0,.25);
		z-index: $zindex-10;
	}
}

.search-hidden{
	display: none;
}
