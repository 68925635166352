@import '__importable.scss';
.button {
    padding: 0.8rem;
    border-radius: $radius-2;
    border: 1px solid $gray-2;
    cursor: pointer;
    width: 3.2rem;
    height: 3.2rem;

    span[data-text-animate="true"] {
        display: grid;
        place-content: center;
        place-items: center;
        grid-template-columns: repeat(3, auto);
        grid-template-rows: repeat(3, auto);
        gap: 0.2rem;
    }

    @media (hover: hover) {
        &:hover:not(:active):not(:focus-visible) {
            span[data-text-animate="true"] {
                transform: translate3d(1px, -1px, 0) !important;

                .rectangle {
                    background-color: $blue-1;
                }
            }
        }
    }
}

.rectangle {
    width: 0.4rem;
    height: 0.4rem;
    background-color: $blue-2;

}