@import '__importable.scss';
.container {
	display: flex;
	flex-direction: column;
	gap: 2.4rem;
	padding-top: 3.2rem;
	max-width: 100vw;

	@media screen and (min-width: $media-md) {
		padding-top: 4rem;
	}

	[class*='Tabs_tabs-list'] {
		display: flex;
	}
}
.homepagePadding {
	padding-inline: 0.8rem;

	@media screen and (min-width: $media-md) {

		padding-inline: 1.6rem;
	}

	@media screen and (min-width: $media-xl) {
		padding-inline: 3.2rem;
	}
	
	@media screen and (min-width: $media-xxl) {
		padding-inline: 6.4rem;
	}

	h3 {
		@media screen and (max-width: $media-sm) {
			font-size: 2.8rem;
		}
	}
}
.content {
	@extend .homepagePadding;
	scroll-snap-type: x mandatory;
	display: flex;
	width: auto;
	overflow-x: scroll;
	overflow-y: visible;
	gap: 1.2rem;

	@include hide-scroll-bar;
}

.buttonContainer {
	@extend .homepagePadding;	
	padding-top: 2.4rem;
	display: none;

	@media screen and (min-width: $media-md-max) {
		display: flex;
	}
}

.button {
	@include navigationButton;
	display: flex !important;
	position: initial;
	height: 3.2rem;
	width: 3.2rem;
}

.disable {
	background: $gray-2;
	cursor: unset;
}
