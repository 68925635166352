@import '__importable.scss';
.container {
	padding: 4rem 1.6rem;
	overflow-x: hidden;
	position: relative;

	@media screen and (min-width: $media-xl) {
		padding: 4rem 3.2rem;
	}
	@media screen and (min-width: $media-xxl) {
		padding: 4rem 6.4rem;
	}
}

.header {
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: center;
}

.slide {
	height: auto;
	max-width: 35rem;

	&Featured {
		height: auto;
		max-width: 32rem;
		
		&__hideMobile {
			display: none;
			@media screen and (min-width: $media-sm) {
				display: block;
			}
		}
	}
}

.featuredCollection {
	height: 100%;
	max-width: 32rem;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.16) 55.21%, rgba(0, 0, 0, 0.5) 100%);
	border-radius: 0.8rem;
	position: relative;
	align-items: end;

	.featuredImage {
		position: absolute;
		height: 100%;
		width: 100%;
		z-index: $zindex-behind;
		background-size: cover;
		object-fit: cover;
		background-position: center;
		background-repeat: no-repeat;
		border-radius: 0.8rem;
	}

	.featuredContent {
		text-align: left;
		color: white;
		padding: 1.6rem;

		&Title {
			text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.56), 0px 4px 4px rgba(0, 0, 0, 0.32);
		}
	}
}

.chevron {
	color: $blue;
}

.productCarousel {
	height: 6.4rem;
	justify-content: space-between;
	padding: 24px 16px 0px 16px;
	position: relative;

	.prevButton {
		@include navigationButton;
		right: 5.6rem;
		height: 3.2rem;
		width: 3.2rem;
	}

	.nextButton {
		@include navigationButton;
		right: 1.6rem;
		height: 3.2rem;
		width: 3.2rem;
	}

	.disable {
		background: $gray-2;
		cursor: unset;
	}

	@media (max-width: $media-md) {
		.prevButton, .nextButton {
			display: flex;
		}
	}
}