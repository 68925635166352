@import '__importable.scss';
.container {
	background-color: $gray-2;
}

.grid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(min(30rem), 1fr));
	gap: 1.6rem;
	justify-items: center;
	margin-bottom: 6rem;

	@media screen and (max-width: $media-md) and (min-width: $media-sm) {
		grid-template-columns: 1fr 1fr;
	}
}

.card {
	color: $gray-5;
	position: relative;

	&:hover {
		opacity: 0.6;
		cursor: pointer;
	}

	> img {
		object-fit: cover;
		width: 42rem;
	}
	> img + div {
		border-radius: 0 0 $radius-5 $radius-5;
	}
}

.gridContainer {
	margin: 0 10rem;
	gap: 2.4rem;

	> h2 {
		margin: 4rem 0;
	}

	@media screen and (max-width: $media-lg) {
		margin: 0 2.5rem;
	}
}

.childContainer {
	background-color: $white;
	height: 22.7rem;
	position: relative;

	> * {
		position: absolute;
		padding: 2.4rem 2.4rem 1.6rem;
	}

	> *:first-child {
		top: 0;
	}

	> *:last-child {
		bottom: 0;
	}
}
