@import '__importable.scss';
.container {
	width: 100%;
}

.image {
	height: 12.8rem;
	width: 100%;
	object-fit: contain;
	border-radius: $radius-4;
	background-color: $gray-1;
	border: 1px solid $gray-2;
}

.iconCircle {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	border: 1px solid $gray-2;
	height: 32px;
	width: 32px;
	position: relative;

	svg {
		position: absolute;
		top: 50%;
		left: 50%;
		translate: -50% -50%;
	}

	@media (hover: hover) {
		&:hover {
			background-color: $gray-2;
		}

		&:active {
			background-color: $gray-2;
			border-color: $gray-3;
		}
	}
}

.skeleton {
	&__title,
	&__notice {
		font-size: 1.6rem;
		line-height: 150%;
		width: 40%;
		background-color: $white;
		height: 2.4rem;
	}

	&__notice {
		margin-bottom: 0.8rem;
	}

	&__description {
		font-size: 1.6rem;
		line-height: 150%;
		width: 100%;
		background-color: $white;
		height: 5rem;
	}

	&__image {
		@include skeleton-loader();
		width: 100%;
		height: 12.8rem;
	}
}
