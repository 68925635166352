@import '__importable.scss';
$height: 24rem;

.container {
	padding-inline: 0.8rem;
	padding-block: 3.2rem;

	.copy {
		margin-bottom: 2.4rem;

		.heading {
			margin-bottom: 0.5rem;
		}

		.description {
			font-weight: 500;
		}
	}

	@media screen and (min-width: $media-sm) {
		padding-block: 4rem;
    }

    @media screen and (min-width: $media-md-max) {
		padding-inline: 1.6rem;
    }

    @media screen and (min-width: $media-xl) {
		padding-inline: 3.2rem;
    }

    @media screen and (min-width: $media-xxl) {
		padding-inline: 6.4rem;
    }

    @media screen and (min-width: $max-width) {
		padding-inline: 12.8rem;
    }

	&--no-padding {
		padding-inline: 0;
		padding-block: 0;
	}
}

.interactive-card-container {
	flex: 1;

	.interactive-card {
		height: $height;
		width: 100%;
		border-radius: $radius-4;
		min-width: 35.2rem;
	}
}

.interactive-card-ctfl {
	width: 100%;
	[class*="InteractiveCard_container"] {
		height: $height;
		width: 100%;
		border-radius: $radius-4;
		min-width: 35.2rem;
	}
	[class*="InteractiveCard_header"]{
		height: 100%;
		padding-block: 2.4rem !important;
		padding-inline: 2.4rem !important;
	
	}
}

.interactive-card-header {
	height: 100%;
	padding-block: 2.4rem !important;
	padding-inline: 2.4rem !important;
}

.summary-cards-container-ctfl{
	flex: auto;
	height: $height;

	@media screen and (min-width: $media-md-max) {
		display: flex;
	}

	@media screen and (min-width: $media-xxxl-max) {
		flex: 1;
	}

	[class*="ImageTitleTextStack_container"] {
		display: none;
		width: 100%;
		height: 100%;

		&[data-index="0"],
		&[data-index="1"] {
			@media screen and (min-width: $media-md-max) {
				display: block;
			}
		}

		&[data-index="2"] {
			@media screen and (min-width: $media-xl) {
				display: block;
			}
		}
	}
}

.summary-cards-container {
	flex: auto;
	max-width: 90rem;
	height: $height;
	display: none;

	@media screen and (min-width: $media-md-max) {
		display: flex;
		height: 100%;
	}

	@media screen and (min-width: $media-xxxl-max) {
		flex: 1;
	}

	.card {
		display: none;
		width: 100%;
		height: 100%;

		&[data-index="0"],
		&[data-index="1"] {
			@media screen and (min-width: $media-md-max) {
				display: block;
			}
		}

		&[data-index="2"] {
			@media screen and (min-width: $media-xl) {
				display: block;
			}
		}
	}
}
