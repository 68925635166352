@import '__importable.scss';
.section {
	background: $gray-2;
}

.sectionWrapper {
	padding: 2.4rem 1.6rem;

	@media screen and (min-width: $media-xl) {
		padding-inline: 3.2rem;
	}

	@media screen and (width >= $media-xxl) {
		padding-inline: 6.4rem;
	}

	@media screen and (width >= $max-width) {
		padding-inline: 12.8rem;
	}
}

.heading {
	margin: 0;
}

.loading-wrapper {
	height: 45rem;
	width: 100%;
	align-self: center;
	overflow: hidden;

	@media screen and (min-width: $media-md) {
		height: 50rem;
	}

	@include loading-wrapper-shimmer;
}
