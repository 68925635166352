@import '__importable.scss';
$cta-height: 1.4rem;

.container {
	height: calc(100% - $cta-height);
	padding-inline: 1.6rem;
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;
	
    .title {
		padding: 1.6rem 0 1.6rem 0;
		h6{
			margin-bottom: 0;
		}
		@media screen and (min-width: $media-md) {
			padding: 2rem 0 2rem 0;
			
		}
	}

	.options-container {
		display: flex;
		flex-flow: column nowrap;
		gap: 0.8rem;
	}
	.one-time-button {
		margin-top: 1.6rem;
		justify-content: center;
		margin-bottom: 1.6rem;

		@media screen and (max-width: $media-md) {
			padding-bottom: 0.8rem;
		}
	}
}

.subcription-cadence-disabled {
	opacity: 0.4;
	pointer-events: none;
}

.options {
	display: flex;
	flex-flow: column nowrap;
	gap: 2.5rem;
}
