@import '__importable.scss';
$headerHeight--mobile: 3.7rem;
$headerHeight--desktop: 5.6rem;
$listWidth--default: 18.6rem;
$listWidth--tablet: 24.6rem;
$listHeight: 28.7rem;

.root {
	position: relative;

	/* Right positioning for last child + overlay div */
	&:last-child {
		[class*='list'] {
			left: unset;
			right: 0;

			+div {
				right: 0;
			}
		}
	}
}

.button {
	flex: 1 0 0;
	align-items: center;
	justify-content: space-between;
	color: $gray-4;
	font-family: var(--poppins);
	font-size: 1.2rem;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	padding: 0.8rem 0.4rem 0.8rem 0.8rem;
	cursor: pointer;
	appearance: none;
	@include non-selectable;

	&.is-opened {
		font-weight: 500;
		color: $gray-5;
		border-color: $blue-1;
	}

	.count {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 1rem;
		height: 1.2rem;
		padding: 0.4rem;
		border-radius: 10rem;
		background-color: $gray-2;
		font-size: 1rem;
		font-family: inherit;
		font-weight: 500;
		font-style: inherit;
		line-height: inherit;
	}

	&:disabled[disabled] {
		color: $gray-4;
		border-color: $gray-2;
		background-color: $gray-2;
	}

	@media (hover: hover) {
		&:not(.is-opened):hover {
			border-color: $gray-3;
			box-shadow: 0.5px -0.5px 0 0.5px $gray-3 inset;
		}
	}

	@media screen and (width > $media-md) {
		font-size: 1.4rem;
		line-height: 146%;
		padding: 1rem 0.8rem;
	}
}

/* Passes through to FacetRefinementList to control open/close state */
.list {
	display: flex;
	flex-flow: column;
	align-items: flex-start;
	gap: 0.4rem;
	position: absolute;
	top: calc($headerHeight--mobile);
	left: 0;
	opacity: 0;
	visibility: hidden;
	width: 100%;
	min-width: $listWidth--default;
	height: $listHeight;
	padding: 0.4rem 0;
	background-color: $white;
	border: 1px solid $gray-1;
	border-radius: 0.4rem;
	box-shadow: 0 0.8rem 0.8rem 0 rgba(0, 0, 0, 0.16);
	transition: opacity 150ms linear, transform 150ms linear;
	pointer-events: auto;
	overflow-y: scroll;
	z-index: $zindex-4; // Matches FilterHeader.tsx .container z-index
	@include non-selectable;

	@supports (-webkit-overflow-scrolling: touch) {
		-webkit-overflow-scrolling: touch;
	}

	>li {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		align-self: stretch;
		padding: 0.4rem 0;
		gap: 0.4rem;
		width: 100%;
		cursor: pointer;
		pointer-events: inherit;

		&[class*='RefinementSwatch'] {
			padding: 0.8rem 0.8rem 0.8rem 1.2rem;
		}

		&[class*='RefinementOption'] {
			>* {
				width: 100%;
			}
		}
	}

	+div {
		display: none;
		position: absolute;
		top: calc($headerHeight--mobile);
		height: $listHeight;
		width: 100%;
		min-width: $listWidth--default;
		border: 1px solid $gray-1;
		border-radius: 0.4rem;
		clear: both;
		background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 86.46%, rgba(255, 255, 255, 0.75) 100%);
		pointer-events: none;
		z-index: $zindex-5;
	}

	&.is-opened {
		opacity: 1;
		visibility: visible;
		transform: translateY(0.4rem);
		transition: opacity 150ms linear, transform 150ms linear;
		pointer-events: auto;

		+div {
			display: block;
			transform: translateY(0.4rem);
		}
	}

	@media screen and (width > $media-md) {
		top: calc($headerHeight--desktop - 1rem);
		min-width: $listWidth--tablet;

		+div {
			top: calc($headerHeight--desktop - 1rem);
			min-width: $listWidth--tablet;
		}
	}

	@media screen and (width > $media-xl) {
		min-width: $listWidth--default;

		+div {
			min-width: $listWidth--default;
		}
	}

}

.list, .list + div {
	// iPhone 13 Mini
	/* stylelint-disable */
	@media only screen and (min-width: 360px) and (max-height: 767px) {
		/* stylelint-enable */
		height: calc($listHeight - 8.7rem);
		overscroll-behavior-y: none;
		scroll-boundary-behavior: none;
		
		@supports (-webkit-overflow-scrolling: touch) {
			-webkit-overflow-scrolling: touch;
		}
	}

}