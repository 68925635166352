@import '__importable.scss';
.cardContainer {
	background: $gray-0;
	border-radius: $radius-6;
	padding: 2rem;
	width: 100%;
	height: 100%;
	min-height: 33.8rem;
	min-width: 32.7rem;
	position: relative;
	scroll-snap-align: center;
	
	@media screen and (min-width: $media-sm) {
		min-height: 41.3rem;
	}
}

.rewardsWidget {
	border: 1px solid $gray-5;
}

.label {
	padding: 0.15rem 0.8rem;
	border-radius: 0.4rem;
	margin: 1.6rem 0 0.8rem !important;
	max-width: fit-content;
}

.ordersLabel {
	background: $gray-2;
	color: $gray-5;
}

.rewardsLabel {
	background: $blue-1;
	color: $gray-0;
}

.remainingItems {
	background: $gray-1;
	border-radius: 0.4rem;
	color: $gray-3;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 500;
	height: 2.4rem;
}

.buttons {
	padding: 1.6rem;
}

.cleanButton {
	@media screen and (width < $media-md) {
		font-size: 1.2rem;
	}
}
