@import '__importable.scss';
.container {
	background-color: $gray-1;
	padding: 4.8rem 0;
	margin: 0 auto;
	width: 100%;

	h3 {
		text-align: center;
		margin-bottom: 3.2rem;
	}

	&__swiper-slide {
		height: auto;
		width: 27.6rem;

		@media screen and (min-width: $media-md-max) {
			width: 30.6rem;
		}

		@media screen and (min-width: $media-xl) {
			width: 31.4rem;
		}

		@media screen and (min-width: $media-xxl) {
			width: 32.2rem;
		}

		@media screen and (min-width: $max-width) {
			width: 41rem;
		}
	}
}

.prevButton {
	@include navigationButton;
	top: 50%;
	left: 1.6rem;
}

.nextButton {
	@include navigationButton;
	top: 50%;
	right: 1.6rem;
}

.disable {
	background: $gray-2;
	cursor: not-allowed;
}