@import '__importable.scss';
.container {
	border-radius: 0.8rem;
	margin-bottom: 1.6rem;

	&.horizontal {
		display: flex;
		flex-direction: row;
		align-items: center;

		.contentCardImg {
			max-height: 32rem;
		}

		.contentCardTextContainer {
			padding-bottom: 2.4rem;
		}

		@media screen and (max-width: $media-sm) {
			display: block;
		}
	}

	&.textContainer,
	&.imgContainer {
		flex: 1;
	}

	&img {
		margin: auto auto 0;
	}
}

.contentCardTextContainer {
	padding: 2rem 0.5rem 0 0.5rem;
	flex: 1;

	h5 {
		padding-bottom: 0.8rem;
	}

	@media screen and (min-width: $media-sm) {
		padding: 4rem 2.5rem 0 2.5rem;
	}
}

.contentCardImgContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;

	& span {
		display: flex !important;
		align-self: end;
	}

	& img {
		height: 100%;
	}
}
