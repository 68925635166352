@import '__importable.scss';
.container {
	border-radius: 0.8rem;
	border: 0.1rem solid $gray-2;
	background: $gray-1;
	display: grid;
	padding: 1.6rem;
	grid-template-columns: repeat(2, auto);
	grid-gap: 0.8rem;
}

.priceBox {
	border-radius: 0.4rem !important;
	padding: 1.6rem;
	display: flex;
	background: white;
	cursor: pointer;
	border: 0.1rem solid $gray-2;
	line-height: 2.1rem;
	font-size: 1.4rem;
	font-weight: 400;
	letter-spacing: 0;
	text-align: left;
	align-items: center;

	&:hover {
		border-color: $blue-tint;
		box-shadow: -0.1rem 0.1rem 0 $blue-tint;
	}
}

$radio-size: 1.6rem;

.priceRadio {
	border-radius: 50%;
	box-shadow: 0 0 0 0.2rem white, 0 0 0 0.4rem $gray-3;
	width: $radio-size;
	height: $radio-size;
	margin-right: 1.6rem;
	background: white;

	@media (hover: hover) {
		&:hover {
			background: $gray-2;
			width: $radio-size;
			height: $radio-size;
			border: 0.3rem solid white;
			border-radius: 50%;
			box-shadow:  0 0 0 0.2rem $gray-3, 0 0 0 0.8rem $gray-2;
		}
	}
}

.active {
	border: unset;
	box-shadow: 0 0 0 0.2rem white, 0 0 0 0.4rem $blue;
	width: $radio-size;
	height: $radio-size;
	background: $blue;

	@media (hover: hover) {
		&:hover {
			border: unset;
			box-shadow: 0 0 0 0.2rem white, 0 0 0 0.4rem $blue;
			width: $radio-size;
			height: $radio-size;
			background: $blue;
		}
	}
}
