@import '__importable.scss';
.loading-wrapper {
	height: 45rem;
	width: 100%;
	align-self: center;

	@media screen and (min-width: $media-md) {
		height: 50rem;
	}

	@include loading-wrapper-shimmer;
}
