@import '__importable.scss';
.header {
	height: 4rem;
	position: relative;
	top: -1rem;
	left: -2rem;
	border-bottom: 1px solid $gray-2;
	width: calc(100% + 4rem);
	background-color: $gray-0;
	z-index: 2;
	padding: 0 1.6rem;

	>p {
		position: relative;
		top: -1.2rem;
	}
}

.collections {
	margin-top: -1.6rem;
	padding-block: 1.6rem;
	overflow-y: scroll;
	max-height: 60vh;
	z-index: 1;

	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}

	@media screen and (min-width: $media-sm) {
		max-height: 75vh;
	}
}

.modal {
	padding: 2rem 2rem 0;
	max-width: 64rem;

	@media screen and (min-width: $media-sm) {
		bottom: 0;
		translate: -50% 0;
		top: unset;
		border-radius: $radius-6 $radius-6 0 0;
	}
}
