@import '__importable.scss';
.container {
	background-color: $blue;
	padding: 5.6rem 0;
	text-align: center;

	h2 {
		color: $white;
		padding: 0 1.4rem;
	}

	> form {
		> div {
			margin: 0 1.6rem;
			display: inline-flex;
			justify-content: center;
			border-radius: 0;

			.emailInputContainer {
				background-color: $white;
				border: none;
				border-radius: 0.8rem 0 0 0.8rem;
				margin: 0;

				&:focus-visible,
				&:focus-within {
					box-shadow: unset;
				}

				&:hover:not(:active):not(:focus-visible) {
					outline: none;
				}
			}

			> button {
				width: 18rem;
				height: 5rem;
				border-bottom-left-radius: 0;
				border-top-left-radius: 0;

				&:enabled:hover {
					background-color: $green-1;
				}
			}
		}

		.successMessage {
			margin: 1.6rem;
			border-bottom-right-radius: 0;
			border-top-right-radius: 0;

			> p {
				color: $white;
			}
		}
	}

	.additionalInfo {
		margin: 0 auto;
		width: 80%;
		color: rgb(115, 143, 222);
	}

	@media screen and (min-width: $media-sm) {
		> form {
			> div {
				.emailInputContainer {
					width: 30rem;
					height: 5rem;
				}
			}
		}
		.additionalInfo {
			width: 50rem;
		}
	}

	.content {
		background: $blue;
		padding: 1.6rem 0rem;
		flex-direction: column;
		text-align: left;
		gap: 2.6rem;
		width: 100%;

		h3, p {
			color: $white;
		}

		form {
			button {
				height: max-content;
			}

			div {
				margin: 0;
			}

			input {
				height: 5rem;
			}
		}

		@media screen and (min-width: $media-xl) {
			padding: unset;
		}

		@media screen and (min-width: $media-xl) {
			padding: 0 3.2rem;
			flex-direction: row;
			gap: 4rem;
			align-items: center;

			>div,
			>form {
				width: 100%;

			}
		}

		@media screen and (min-width: $media-xxl) {
			width: 60%;
			flex-direction: row;
			gap: 6.4rem;
			align-items: center;

			>div,
			>form {
				width: 100%;

			}
		}

		.errorMessage {
			color: $red-1;
		}
	}
}

.isHPRedesign {
	padding: 2.4rem 1.6rem;

	@media screen and (min-width: $media-md-max) {
		padding: 3.2rem 1.6rem;
	}

	@media screen and (min-width: $media-xl) {
		padding-inline: 0;
	}
}
