@import '__importable.scss';
.container {
	position: relative;
	top: 1rem;

	.subHeader {
		display: none;
	}

	h3 {
		font-size: 2rem;
	}

	@media screen and (min-width: $media-sm) {
		top: 50%;
		translate: 0 -50%;

		.subHeader {
			display: block;
		}

		h3 {
			font-size: clamp(2.4rem, 4vw, 3.2rem);
		}
	}
}
