@import '__importable.scss';
.container {
	padding: 0.8rem 1.6rem 0.8rem 0.8rem;
	align-items: flex-start !important;

	> button {
		margin-top: 4px;
	}

	&:has(+ button[data-state='checked']) {
		.price {
			color: black;
		}
	}
}

.editableLensButton {
	span {
		min-width: 3.2rem;
		min-height: 3.2rem;
	}
}

.mobileImageView {
	min-width: 5.6rem;
	height: 6.5rem;
}

.upsellImage {
	@extend .mobileImageView;

	@media screen and (min-width: $media-md-lg) {
		width: 7.2rem;
		height: 5.4rem;
	}
}

.upsell {
	@include hoverable($gray-3);
}
