@import '__importable.scss';
.container {
	background-color: $gray-0;
	padding: 3.2rem 0 6.4rem;
	margin: 0 auto;
	width: 100%;

	@media (min-width: $media-sm) {
		padding: 4rem 0 6.4rem;
	}

	&__swiper-slide {
		height: 30vw !important;
		width: 30vw !important;
		min-height: 29.8rem;
		min-width: 29.8rem;
		max-height: 43.2rem;
		max-width: 43.2rem;
	}

	.swiper,
	.copy {
		padding-inline: 0.8rem;

		@media screen and (min-width: $media-md-max) {
			padding-inline: 1.6rem;
		}

		@media screen and (min-width: $media-xl) {
			padding-inline: 3.2rem;
		}

		@media screen and (min-width: $media-xxl) {
			padding-inline: 6.4rem;
		}

		@media screen and (min-width: $max-width) {
			padding-inline: 12.8rem;
		}
	}
}

.prevButton {
	@include navigationButton;
	right: 5.6rem;
	left: auto;
	height: 3.2rem;
	width: 3.2rem;

	@media (max-width: $media-md) {
		display: flex;

		@media (hover: hover) {
			&:hover {
				background-color: $white;
			}
		}
	}
}

.nextButton {
	@include navigationButton;
	right: 1.6rem;
	left: auto;
	height: 3.2rem;
	width: 3.2rem;

	@media (max-width: $media-md) {
		display: flex;

		@media (hover: hover) {
			&:hover {
				background-color: $white;
			}
		}
	}
}

.disable {
	background: $gray-2;
	cursor: not-allowed;
}

.socialContainer {
	display: flex;
	flex-flow: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 0.8rem 0 2.4rem;

	.socialList {
		margin: 0;
		display: flex;
		flex-flow: row wrap;
		justify-content: space-around;
		align-items: center;
		gap: 1.4rem;

		@media screen and (min-width: $media-md) {
			justify-content: flex-start;
		}

		li {
			flex-grow: 1;
			display: flex;
			justify-content: center;

			&:last-child {
				svg {
					height: 1.9rem;
				}
			}
		}

		a {
			color: $white;
			height: 2.4rem;
			width: 2.4rem;
			font-size: 2rem;
			line-height: normal;
			display: flex;
			align-items: center;
			justify-content: center;

			&:hover {
				opacity: 0.6;
			}
		}

		svg {
			height: 2.2rem;
			width: 2.2rem;

			path {
				fill: black;
			}
		}
	}
}

.navigationButtonsContainer {
	position: relative;
	padding-top: 2.4rem;
	margin-inline: 0.8rem;

	@media screen and (min-width: $media-md-max) {
		margin-inline: 1.6rem;
	}

	@media screen and (min-width: $media-xl) {
		margin-inline: 3.2rem;
	}

	@media screen and (min-width: $media-xxl) {
		margin-inline: 6.4rem;
	}

	@media screen and (min-width: $max-width) {
		margin-inline: 12.8rem;
	}
}

.divider {
	border-bottom: 1px solid $gray-2;
	margin-top: 1.6rem;

	@media (min-width: $media-sm) {
		margin-top: 3.2rem;
	}
}
