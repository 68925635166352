@import '__importable.scss';
.container {
	border-radius: 4px;
	border: 1px solid $gray-2;
	height: 100%;

	&__image {
		position: relative;
		min-height: 30rem;

		img {
			border-radius: $radius-2 $radius-2 0 0;
			object-fit: cover;
			object-position: top 30% right 0;
		}
	}

	&__text {
		background-color: $white;
		min-height: 21rem;
		height: 100%;
		border-radius: 0 0 $radius-2 $radius-2;
	}
}