@import '__importable.scss';
.topHero {
	max-width: 44.8rem;
	justify-content: center;
	height: 100%;
	padding: 0 5.5rem;
	box-sizing: content-box;

	> button {
		margin: 3.2rem 0 2rem 0;
		width: 100%;

		@media screen and (min-width: $media-md) {
			max-width: 25.6rem;
		}
	}

	@media screen and (max-width: $media-md) {
		max-width: 100%;
		justify-content: space-between;
		text-align: center;
		align-items: center;
		padding: 2rem 0 0 0;
	}
}

.perfectFor {
	padding: 4.8rem 6.4rem 6.4rem;

	.header {
		margin-bottom: 0.8rem;
	}

	.badges {
		display: block;
		margin-top: 1.6rem;

		.badge {
			.image {
				margin-top: 3.2rem;
			}

			.badgeHeader {
				margin: 1.6rem 0 0.8rem 0;
			}

			> p {
				text-align: center;
			}
		}

		@media screen and (min-width: $media-md) {
			display: grid;
			grid-template-columns: repeat(3, minmax(auto, 38rem));
			grid-column-gap: 3.2rem;
		}
	}
}

.carousel {
	max-width: $max-width;
	padding: 6.4rem 0;

	.header {
		margin-bottom: 0.8rem;
	}
}

.itemCard {
	padding:       1.6rem;
	background:    $gray-1;
	border:        1px solid $gray-2;
	border-radius: 0.8rem;
	text-align:    center;
	height: initial;

	> div {
		aspect-ratio: 2 / 1;

		img {
			opacity:        0;
			height:         100%;
			width:          100%;
			margin:         0 0 0.4rem 0;
			object-fit:     contain;
			mix-blend-mode: multiply;
			transition:     opacity 250ms ease-out;

			&.loaded {
				opacity: 1;
			}
		}
	}
}

.scroll {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0 1rem !important;
	gap: 1.6rem;
	width: 100%;
	scroll-snap-align: center;

	@media screen and (min-width: $media-lg) {
		padding: 0 6.4rem !important;
	}

	.swiper-wrapper {
		max-width: $max-width;
	}
}

.prevButton {
	left: 3.2rem;

	@include navigationButton;
}

.nextButton {
	right: 1.6rem;
	@include navigationButton;
}

.hide {
	display: none;
}

.getInContact {
	background: $gray-1;
	gap: 0.8rem;
	padding: 3.2rem 1.6rem;

	.formTitle {
		margin: auto;
	}

	.formContainer {
		width: 100%;
		height: 40rem;
	}
}
