@import '__importable.scss';
.loadMoreButton {
	width: 100%;
	height: 4.8rem;
	margin: 1.6rem 0;
}

.loadingDots {
	&[datatype='infinite'] {
		margin: 2rem;
	}
}
