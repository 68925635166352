@import '__importable.scss';
.anchorArticle {
	cursor: pointer;

	&:hover {
		opacity: 0.6;
	}
}

.section {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(36rem, 1fr));
	justify-content: center;
	padding: 4rem 0 0 0;

	@media (max-width: $media-xsm) {
		padding: 0 1.6rem;
	}
}

.imageContainer {
	@media (min-width: $media-xxl) {
		height: 31.7rem;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: $radius-4 0 0 $radius-4;

		@media (max-width: $media-md) {
			border-radius: $radius-4 $radius-4 0 0;
		}
	}

	@media (max-width: $media-xsm) {
		height: 26.2rem;
	}

	@media (max-width: $media-md) {
		height: 52.3rem;
	}

	@media (max-width: $media-lg) {
		height: 40.3rem;
	}

	@media screen and (min-width: $media-lg) {
		height: 52.3rem;
	}
}

.textContainer {
	border-radius: 0 $radius-4 $radius-4 0;
	padding: 4rem 3.2rem;
	background-color: $white;

	@media (max-width: $media-md) {
		margin-bottom: 1.2rem;
		border-radius: 0 0 $radius-4 $radius-4;
		padding: 1.6rem;
	}

	> div {
		width: fit-content;
		margin-bottom: 1rem;
	}

	> p {
		max-width: 70rem;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
	}
}
