@import '__importable.scss';
$notificationBoxPadding: 1.2rem;

.container {
	border-radius: 0.8rem;
	padding: $notificationBoxPadding;
	position: relative;
}

.closeIconWrapper {
	position: absolute;
	top: $notificationBoxPadding;
	right: $notificationBoxPadding;
	background: $white;
}

.title {
	font-size: 1.6rem !important;
	margin-bottom: 0.5rem !important;
}

.body {
	font-size: 1.2rem;
	font-weight: 500;
}

.messageContainer {
	display: flex;

	p {
		margin: 0;
	}
}

.messageContent {
	display: flex;
	flex-flow: column;
	margin-left: 0.8rem;
	width: 100%;
	align-items: flex-start;

	a {
		padding: 0;
	}

	button {
		max-width: fit-content;
		margin-left: auto;
		margin-top: 1.6rem;
	}
}
