@import '__importable.scss';
.container {
	border-radius: 0.8rem;
	border: 1px solid $gray-2;

	&__image {
		position: relative;
		height: 30vw;
		width: 30vw;
		min-height: 29.8rem;
		min-width: 29.8rem;
		max-height: 43.2rem;
		max-width: 43.2rem;
		border-radius: 0.8rem;
		overflow: hidden;

		img {
			border-radius: $radius-2 $radius-2 0 0;
			object-fit: cover;
			object-position: top 30% right 0;
		}
	}

	&__text {
		background-color: $white;
		min-height: 21rem;
		height: auto;
		border-radius: 0 0 $radius-2 $radius-2;
	}

	.video {
		height: 30vw;
		width: 30vw;
		min-height: 29.8rem;
		min-width: 29.8rem;
		max-height: 43.2rem;
		max-width: 43.2rem;
		object-fit: cover;
		position: relative;
	}
}

.gradientOverlay {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-image: linear-gradient(191deg, rgba(0, 0, 0, 0.00) 49.27%, rgba(0, 0, 0, 0.13) 70.64%, rgba(0, 0, 0, 0.50) 92.01%);

	&--large-text {
		background-image: linear-gradient(191deg, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0.13) 40%, rgba(0, 0, 0, 0.5) 100%);
	}
}

.content {
	position: absolute;
	left: 0;
	width: 100%;
}

.copyright {
	position: absolute;
	font-size: 1rem !important;
	top: 1.6rem;
	right: 1.6rem;
	color: $white;
	text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.56), 0px 0px 4px rgba(0, 0, 0, 0.32);
}

.textContainer {
	color: $white;
	position: absolute;
	bottom: 6rem;
	padding: 1.6rem;
}

.ctaContainer {
	align-items: center;
	width: 100%;
	justify-content: space-between;
	padding: 1.6rem;
	position: absolute;
	bottom: 0;
}

.playIcon {
	position: absolute;
	right: 1.6rem;
	bottom: 1.6rem;
}