@import '__importable.scss';
.container {
	position: relative;
	width: 100%;
}

.selectorArea {
	position: absolute;
	border-top: 1px solid rgb(197, 197, 197);
	border-bottom: 1px solid rgb(197, 197, 197);
	width: 99%;
	z-index: 2;
	top: 50%;
	translate: 0 -50%;
	pointer-events: none;
}

.fadeArea {
	position: absolute;
	pointer-events: none;
	width: 100%;
	z-index: 1;

	&:nth-of-type(1) {
		top: 0;
		background: linear-gradient(180deg, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.75) 100%);
	}
	&:nth-of-type(2) {
		bottom: 0;
		background: linear-gradient(180deg, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0.95) 100%);
	}
}

.list {
	display: flex;
	flex-direction: column;
	overflow-y: scroll;
	height: 100%;
	color: black;
	position: relative;
	perspective: 1000px;
	padding: 0 0.8rem;

	&::-webkit-scrollbar {
		width: 0 !important;
	}
}

.item {
	@include non-selectable;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 auto;
	width: 100%;
	font-size: 1.6rem;
	font-weight: 400;
}

.selectedItem {
	color: black;
}
