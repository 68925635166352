@import '__importable.scss';
.container {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	.outer-ring {
		box-sizing: content-box;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transform: rotateY(-180deg) rotateZ(-90deg);
		overflow: visible;
		fill: none;
		z-index: 2;

		&:first-child {
			z-index: 3;
		}

		circle {
			width: inherit;
			height: inherit;
		}

		&__background {
			fill: none;
			stroke: $gray-0;
		}

		&__foreground {
			fill: none;
			stroke: $green-1;
			stroke-linecap: butt;
		}
	}

	.checkmark-icon {
		position: absolute;
		border-radius: 50%;
		fill: none;
		z-index: 1;

		path {
			fill: $green-a11y;
		}
	}
}