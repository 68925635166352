@import '__importable.scss';
.container {
	background: $gray-1;
	gap: 0.8rem;
	padding: 3.2rem 1.6rem;

	.title {
		margin: auto;
	}

	.form {
		width: 100%;
	}

}
