@import '__importable.scss';
.svg {
	margin-top: 0.4rem;
	min-height: 1rem;
	min-width: 1.2rem;
}

.text {
	padding-left: 0.8rem;
}
