@import '__importable.scss';
.section {
	overflow: hidden;
}

.tileContainer {
	overflow: visible;
	max-width: $max-width;
	margin-left: auto;
	margin-right: auto;
	padding: 0 1.6rem;
	width: 100%;

	@media screen and (width >= $media-xl) {
		padding: 0 3.2rem;
	}

	@media screen and (width >= $media-xxl) {
		padding: 0 6.4rem;
	}

	@media screen and (width >= $max-width) {
		padding: 0 12.8rem;
	}
}

.tileTrack {
	position: relative;
	height: 9.6rem;
}

.tileSlider {
	display: flex;
	overflow: visible;
	margin: 0;
	position: unset;
}

.tile {
	width: 16rem !important;
	height: 9.6rem !important;
	border-radius: 0.8rem;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	position: relative;
	background-position: center;
	background-size: cover;
}

.collectionIcon {
	position: relative;
	img {
		position: relative;
		z-index: 99;
		padding: 0 1.2rem;
		height: 100%;
		width: 100%;
		max-height: 5.6rem;
		object-fit: contain;
	}

	@media (max-width: $media-md) {
		img {
			position: relative;
			z-index: 99;
			height: 100%;
			width: 100%;
			padding-left: 0.4rem;
		}
	}
}

.prevButton {
	top: 2.4rem;
	left: 1rem;

	@include navigationButton;
}

.nextButton {
	top: 2.4rem;
	right: 1rem;

	@include navigationButton;
}

.hide {
	display: none;
}

.chevron {
	color: $blue;
}
