@import '__importable.scss';
.tile {
	width: 100%;
	height: 9.6rem;
	border-radius: $radius-4;
	position: relative;
	overflow: hidden;

	display: grid;
	grid-template-areas: 'stack';
	justify-items: end;
	align-items: normal;
	background-color: $gray-2;
	-webkit-border-radius: $radius-4;
	-moz-border-radius: $radius-4;

	--transformLevel: translate3d(0px, 0px, 0);

	> * {
		grid-area: stack;
		z-index: $zindex-2;
		border-radius: $radius-4;
	}

	> img {
		object-fit: cover;
		border-radius: $radius-4;
		position: absolute;
		height: 100%;
		width: 100%;
	}

	a {
		height: 100%;
		width: 100%;
		opacity: 0;
		left: 0;
		top: 0;
		border-radius: $radius-4;
		background: $white;
		z-index: $zindex-4;
		cursor: pointer;

		&:hover {
			background-color: $white;
			opacity: 0.15;
		}

		&:active {
			background-color: $black;
			opacity: 0.2;
		}
	}

	p {
		font-size: 1.2rem;
		line-height: 1.4rem;
		padding: 0.2rem 0.4rem;
		border-radius: $radius-1;
	}

	&-name {
		position: relative;
		width: 100%;
		z-index: $zindex-3;
		overflow: hidden;
		border-radius: $radius-4;
		padding: 0 0.8rem;

		&--noLogo {
			padding: 0 0.8rem 0.8rem 0.8rem;
			background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.16) 55.21%, rgba(0, 0, 0, 0.5) 100%);
		}

		&--lozenge {
			transform: var(--transformLevel);
			transition: all $bezier-2 200ms;
			will-change: transform;
		}

		img {
			height: 100%;
			width: 100%;
			max-height: 5.6rem;
			object-fit: contain;
		}

		h6 {
			color: #fff;
			text-shadow: $text-shadow-1;
			transform: var(--transformLevel);
			transition: all $bezier-2 200ms;
			will-change: transform;
		}
	}

	@media (hover: hover) {
		&:hover {
			cursor: pointer;
			--transformLevel: translate3d(1px, -1px, 0);
		}
	}

	&:active {
		--transformLevel: translate3d(0px, 0px, 0);
	}

	&:focus-visible {
		outline: 0;
		box-shadow: 0 0 0 2px $pink-2;
	}
}
