@import '__importable.scss';
.container {
	background-color: $white;
	border-radius: $radius-7 $radius-7 0 0;
	padding: 4rem 1.6rem;
	width: 100%;
	margin-top: -3.2rem;
	max-width: 57.6rem;
	position: relative;
	z-index: $zindex-1;

	@media screen and (min-width: $media-sm) {
		border-radius: $radius-7;
		margin-top: 0;
		padding: clamp(4rem, 4.5vw, 8rem);
	}
	@media screen and (min-width: $media-xxl) {
		padding: clamp(4rem, 6vw, 8rem);
	}

}

.loginHeader {
	margin-bottom: 2.4rem;
	margin-top: 2.4rem; 
	font-size: 4rem
}

.resetPassword {
	@extend .loginHeader;
	margin-top: 3.2rem;
}

.buttons {
	display: flex;
	gap: 1.6rem;
	margin-top: 3.2rem;
}

.backLink {
	position: absolute;
	top: 1.6rem;
	left: 1.6rem;
	font-size: 1.4rem;
}

