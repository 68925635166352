@import '__importable.scss';
.container {
    position: relative;
	background: rgb(0,0,0,0.12);
	border-radius: 2px;
	overflow: hidden;
}

.bar {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0 2px 2px 0;
    transition: width 400ms ease-in-out;
}
