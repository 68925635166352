@import '__importable.scss';
.container {
	display: flex;
	flex-direction: column;
	padding: 4rem 1.6rem;
	gap: 1.6rem;
	max-width: $max-width;
	justify-content: center;
	align-items: center;
	background-size: cover;
	background-repeat: no-repeat;

    @media screen and (min-width: $media-md) {
		gap: 2.4rem;		
    }

	@media screen and (min-width: $media-xl) {
		flex-direction: row;
		justify-content: space-between;
		padding: 4rem 3.2rem;
		gap: 3.2rem;

		> * {
			flex: 1 0 0;
		}
	}

	@media screen and (min-width: $media-xxl) {
		padding: 4rem 6.4rem;
		gap: 3.2rem;
	}	

	@media screen and (min-width: $max-width) {
		padding: 4rem 12.8rem;
	}	

	> *:last-child:not(:first-child) {
		height: 28.4rem !important;
		width: 100%;
	}
}
