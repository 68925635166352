@import '__importable.scss';
.container {
	display: flex;
	align-items: center;
	flex-shrink: 0;
	gap: 0.4rem;
	color: $gray-5;
	background-color: $gray-2;
	border-radius: 36rem;
	padding: 0.4rem 0.8rem;
	transition: stroke 150ms linear;

	@media (hover: hover) {		
		&:hover {
			cursor: pointer;
			.close-icon {
				stroke: $gray-4;
				transition: inherit;
			}
		}
	}
}

.close-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 1.6rem;
	height: 1.6rem;
	border-radius: 50%;
	transition: inherit;
	stroke: $gray-3;

	path {
		stroke: inherit;
	}
}