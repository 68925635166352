@import '__importable.scss';
.container {
	scroll-margin-top: 10rem;
	:global {
		.yotpo-reviews .main-widget .yotpo-label-container {
			border: 0;
		}

		@media screen and (width < $media-md-max) {
			.yotpo-review.yotpo-regular-box {
				margin: 0;
			}

			.yotpo-reviews-filters.yotpo-active {
				margin: 0;
			}

			.new-yotpo-small-box.reviews {
				margin-inline: 0;
			}
		}

		@media screen and (max-width: $media-lg) {
			.yotpo-regular-box.yotpo-bottomline.bottom-line-items-container {
				display: flex;
				justify-content: center;
			}
		}

		@media screen and (min-width: $media-lg) {
			.yotpo-reviews .main-widget .bottom-line-items {
				display: flex;
				flex-flow: column;
				align-items: center;
			}
		}
	}
}
