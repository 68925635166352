@import '__importable.scss';
.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.8rem;

	@media (hover: hover) {
		&.buildflow:hover:not(:active):not(:focus-visible) {
			background-color: $gray-1;

			.wrapper {
				@include swatch-wrapper;
			}
		}
	}
}

.wrapper {
	@include swatch-wrapper;
}

.swatch {
	@include swatch;
	box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.16);
	position: relative;

	@each $name, $color in $swatchColors {
		&[data-color='#{$name}'] {
			background: $color;
			z-index: inherit;
		}
	}
}

.checkmark {
	width: 100%;
}

.checkmarkBlack {
	path {
		stroke: $gray-5;
	}
}

.rainbow {
	width: 3.2rem;
	height: 3.2rem;
	border-radius: 50%;
	background: conic-gradient(#14c926, #00a3ff, #1400ff, #ff0000, #e6ea08, #14c926);
}

.image {
	@each $name, $image in $swatchImages {
		&[data-image-label='#{$name}'] {
			background-image: url($image);
		}
	}
}

.label {
	text-align: center;
}