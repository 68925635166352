@import '__importable.scss';
.hidden {
	display: none;
}

.error {
	height: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	background-color: $orange-3;
	border: 0;
	border-radius: 0.8rem;
	color: $gray-0;
	margin: 0 0 2.4rem;
	padding: 1rem;

	p {
		align-self: center;
	}
}

.info {
	display: flex;
	flex-direction: row;
	align-items: center;
	background-color: #ccc;
	border: 0;
	border-radius: 0.8rem;
	color: $gray-0;
	margin: 0 0 1.5rem;
	padding: 1rem 1rem 1rem 1.6rem;

	p {
		align-self: center;
	}
}
