@import '__importable.scss';
.container {
	height: auto;

	header {
		height: 100%;
		display: flex;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 9rem;
		border-radius: 1.5rem 1.5rem 0 0;
		padding: 0 3rem;
		color: $white;
		background-color: $gray-5;

		@media screen and (width <= $media-md) {
			padding: 0 2rem;
		}
	}

	.content {
		margin-top: 9rem;
		padding: 1.6rem;
	}
}

.title {
	font-size: 2.6rem;
	font-weight: bold;

	@media screen and (width < $media-lg) {
		font-size: 1.6rem;
		font-weight: 500;
	}
}

.subtitle {
	font-size: 2.2rem;
	font-weight: 500;
	margin-bottom: 4rem;

	@media screen and (width < $media-md) {
		font-size: 1.6rem;
	}
}

.coloredText {
	p {
		font-weight: bold;
		text-align: center;
	}

	p:first-of-type {
		font-size: 1.8rem;
	}

	@media screen and (width < $media-md) {
		p {
			font-size: 1.2rem;
		}

		p:first-of-type {
			font-size: 1.4rem;
		}
	}
}

.verticalSeparator {
	border-left: 0.1rem solid $white;
	height: 4rem;
}

.label {
	margin-top: 0.5rem;
	font-size: 1.3rem;
	font-weight: 400;
}
