@import '__importable.scss';
.modal {
	height: 64rem !important;

	@media screen and (min-width: $media-sm) {
		translate: -50% 0 !important;
		bottom: 0 !important;
		top: auto !important;
		border-radius: $radius-6 $radius-6 0 0 !important;
		max-width: 64rem !important;
		height: 54.4rem !important;
	}
}

.container {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 1.6rem;
	background-color: $gray-0;
}

.nav {
	display: flex;
	justify-content: flex-start;
	align-items: flex-end;
	border-bottom: 1px solid $gray-2;
	height: 4.8rem;
}

.tab-pane {
	display: none;

	&[data-state='active'] {
		display: block;
	}
}

@media screen and (min-width: $media-md) {
	.container {
		max-width: 120em;
		margin: 0 auto;
	}

	.offerings {
		padding: 1.6rem;
	}
}

@media screen and (min-width: $media-lg) {
	.container {
		display: flex;
		width: 100%;
		border-radius: $radius-4;
	}

	.offerings {
		flex: 1;
		padding: 1.6rem;
		background-color: $gray-0;
		height: 100%;
		max-width: 100%;
		display: flex;
		flex-flow: column;
	}
}

.button {
	margin: 1rem 0 0 1.6rem;
	color: $blue;
	font-weight: 500;
	font-size: 1.4rem;
	cursor: pointer;

	.buttonText {
		font-weight: 500 !important;

		&:hover {
			color: $blue-1;
			background: $gray-2;
			padding: 0 0.4rem;
		}
	}
}

.tabsContent {
	padding: 1.6rem 0;
	margin-top: 1.6rem;

	@media screen and (min-width: $media-lg) {
		height: 100%;
	}
	@media screen and (min-width: $media-xl) {
		max-width: unset;
	}

	.content-list-item {
		position: relative;

		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}
