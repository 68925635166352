@import '__importable.scss';
.sidebar {
	height: auto;
	max-width: 100vw;

	.sidebarContent {
		padding-bottom: initial;
		@media screen and (min-width: $media-lg) {
			padding-bottom: 5rem;
		}
	}
}

.howItWorks {
	margin-top: 1.6rem;
	margin-bottom: 0.8rem;
}

.decideDesign {
	margin: 1.6rem 0;
}

.pickPerfectAmount {
	margin: 1.6rem 0;

	span {
		text-align: right;
		color: $blue;
	}

	> p {
		margin-bottom: 1.6rem;
	}
}

.whosItFor {
	padding: 2.4rem 0;
}

.leaveLittleNote {
	margin: 0;
}

.moreInfoIncoming {
	margin-bottom: 0.8rem;
	margin-top: 1.6rem;
}

.tooltip {
	@media screen and (min-width: $media-md) {
		width: max-content;
	}
}

.stickyButton {
	display: flex;
	justify-content: space-between;
	bottom: 0.8rem;
	left: 0.8rem;
	position: fixed;
	background: $white;
	padding: 1.2rem;
	border-radius: 1.6rem;
	border: 1px solid $gray-2;
	width: calc(100% - 1.6rem);
	z-index: $zindex-3; // should sit above page content and footer, behind nav, drawers, build flow
	pointer-events: all;
	max-height: 7.7rem;

	@media screen and (min-width: $media-md-max) {
		max-width: 42rem;
		padding: 1.6rem;
		right: 2%;
		left: unset;
	}

	.cta {
		min-width: 21.1rem;
	}

	.product-title {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		margin-bottom: 0;
	}
}

.stickyGradient {
	background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);
	width: 100%;
	height: 6.4rem;
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: $zindex-1;

	@media screen and (min-width: $media-sm) {
		display: none;
	}
}
